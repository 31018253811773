import { useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Row, Col, Image } from 'react-bootstrap';
import PaymentServices from 'services/payment';
import { routers } from 'router/router';
import { useHistory } from 'react-router';

interface Props {}

const UserSuccessPayment = (props: Props) => {
  let history = useHistory();
  const [session, setSession] = useState({});
  const [backUrl, setBackUrl] = useState(`${routers.settings.path}?active-tab=5`);
  const location = useLocation();
  const sessionId = location.search.replace('?session_id=', '');

  useEffect(() => {   
    async function fetchSession() {
      try {
        const session:any = await PaymentServices.checkoutStripSession(sessionId);
        console.log('sesion info:', session);
        setSession(session);
  
        if (session) {
          let returnUrl =  `settings?active-tab=5`;
          setBackUrl(returnUrl);
        }
      }
      catch(ex){
        console.log('Error:', ex);
      }


    }
    fetchSession();
  }, [sessionId]);

 
  return (
    <>
      <Row className="p-0 m-0">
        <Col xs={12} md={8} className="p-0 text-center">
          <div className="PaymentSuccess-iconContainer flex-item width-grow mb-3">
            <Image src={'/assets/images/confirm-check.png'} height={'80px'}/>
          </div>

          <h2 className="m-3 mt-4">Your payment succeeded!</h2>

          <div className="m-3">
            <h5>We’ve send you a payment complete your account.</h5>
          </div>

          <Button
              className="w-25 p-3 m-3"
              variant="primary"
              onClick={() => history.push(backUrl)}
            >
              <span className="h5 fw-bold">Go User Setting</span>
            </Button>
        </Col>
      </Row>     
    </>
  );

};

export default UserSuccessPayment;
