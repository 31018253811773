/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import YesNoSwitch from 'components/input/YesNoSwitch';
import SelectInput from 'components/input/SelectInput';
import DatePickerInput from 'components/input/DatePicker';
import {
  checkEnableParseJSON,
  formatBoolean,
  optionValues,
  setArrayLength,
} from 'components/helper/helper';
import { getSATTest, updateSATTest } from 'app/testingSlice/testingSlice';

import { debounce } from 'lodash';
import TextInput from 'components/input/textInput';
import SATItem from './SATItem';

const SATSampleItem = {
  test_date: '',
  total_score: '',
  reading_writing_score: '',
  math_score: '',
  essay_scores: '',
  essay_reading: '',
  essay_analysis: '',
  essay_writing: '',
};

const SATTest = () => {
  const [loading, setLoading] = React.useState(false);
  const testing = useAppSelector((store) => store.testing);
  const dispatch = useAppDispatch();
  const history = useHistory();  
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);

  const formik = useFormik({
    initialValues: {
      is_have_sat_scores_report: true,
      is_took_before_march_2016: true,
      is_took_after_march_2016: true,
      month: '',
      year: 0,
      times_taken_sat: '',
      number_of_past_sat_scores: 0,
      highest_total_score: 0,
      test_date: '',
      highest_evidence_reading_writing_score: 0,
      evidence_reading_writing_score: 0,
      evidence_reading_writing_date: '',
      highest_math_score_based_evidence_reading_writing_score: 0,
      math_score: 0,
      math_reading_writing_date: '',
      combined_essay_test_date: '',
      combined_essay_reading_score: 0,
      combined_essay_reading_score_sub: 0,
      combined_essay_analysis_score: 0,
      combined_essay_analysis_score_sub: 0,
      combined_essay_writing_score: 0,
      combined_essay_writing_score_sub: 0,
      future_sat_tests_plan_to_take: 0,
      future_testing_date_1: '',
      test_score_math: 0,
      test_score_reading: 0,
      test_score_writing_and_language: 0,

      taken_sat_essay_option: '',
      combined_essay_score: null,
      combined_essay_date: '',
      csu_info: {
        collegeBoardId: '',
        sat_score: [SATSampleItem],
      },
    },
    onSubmit: async (values) => {
      try {
        let number_of_past_sat_scores = values.number_of_past_sat_scores ? parseInt(`${values.number_of_past_sat_scores}`):0;
        let newValues:any = {
          ...values,
          student_id: studentId,
          csu_info: JSON.stringify(values.csu_info),
          number_of_past_sat_scores: `${values.number_of_past_sat_scores}`,
          is_have_sat_scores_report: number_of_past_sat_scores>0,
          future_testing_date_1: JSON.stringify(formFutureTestDate.values),
        };
        await dispatch(updateSATTest(newValues));
      }
      catch(ex){
        console.log('Error: ', ex);
      }
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = () => {
    formik.handleSubmit();
    const nextRoute = Object.keys(testing.roleTest).find(
      (key, index) =>
        index > Object.keys(testing.roleTest).indexOf('satTest') && testing.roleTest[key] === true
    );
    history.push(nextRoute ? routers?.[nextRoute]?.path : routers.otherTest.path);
  };

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    try {
      if (token && studentId) {
        setLoading(true);
        let res = await dispatch(getSATTest(studentId));
        const dataLoaded: any = res.payload;
        if (dataLoaded) {
          let values: any = {
            ...dataLoaded,
            number_of_past_sat_scores: dataLoaded.number_of_past_sat_scores ? parseInt(dataLoaded.number_of_past_sat_scores) : 0, 
            csu_info: {
              ...formik.values.csu_info,
              ...checkEnableParseJSON(dataLoaded.csu_info),
            },
          };

          formik.setValues(values);
          formFutureTestDate.setValues({
            ...formFutureTestDate.values,
            ...checkEnableParseJSON(dataLoaded.future_testing_date_1),
          });
        }
        setLoading(false);
        formik.setStatus('idle');
      }
      else {
        handleLogout();
      }    
    }
    catch(ex){
      console.log('Error: ', ex);
    }
  };

  React.useEffect(() => {
    getDetail();
  }, []);

  const formFutureTestDate = useFormik({
    initialValues: {
      test1: '',
      test2: '',
      test3: '',
    },
    onSubmit: () => {},
  });

  React.useEffect(() => {
    if (formik.dirty && formatBoolean(formik.values.is_have_sat_scores_report) === false) {
      formik.setValues({
        is_took_before_march_2016: true,
        is_took_after_march_2016: true,
        month: '',
        year: 0,
        times_taken_sat: '',
        number_of_past_sat_scores: 0,
        highest_total_score: 0,
        test_date: '',
        highest_evidence_reading_writing_score: 0,
        evidence_reading_writing_score: 0,
        evidence_reading_writing_date: '',
        highest_math_score_based_evidence_reading_writing_score: 0,
        math_score: 0,
        math_reading_writing_date: '',
        combined_essay_test_date: '',
        combined_essay_reading_score: 0,
        combined_essay_reading_score_sub: 0,
        combined_essay_analysis_score: 0,
        combined_essay_analysis_score_sub: 0,
        combined_essay_writing_score: 0,
        combined_essay_writing_score_sub: 0,
        future_sat_tests_plan_to_take: 0,
        future_testing_date_1: '',
        test_score_math: 0,
        test_score_reading: 0,
        test_score_writing_and_language: 0,
        is_have_sat_scores_report: false,
        taken_sat_essay_option: '',
        combined_essay_score: null,
        combined_essay_date: '',
        csu_info: {
          collegeBoardId: '',
          sat_score: [SATSampleItem],
        },
      });
      formFutureTestDate.resetForm();
    }
  }, [formik.values.is_have_sat_scores_report]);

  // Auto save function ==========
  const notInitialRender = React.useRef(false);
  React.useEffect(() => {
    debounceSaving();
  }, [formik.values, formFutureTestDate.values]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (notInitialRender.current) {
        if (!loading) formik.handleSubmit();
      } else {
        notInitialRender.current = true;
      }
    }, 1500),
    []
  );
  // Auto save function ==========
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[
          { title: 'General', percentOnProcess: 25 },
          { title: 'Reading/Writing', percentOnProcess: 40 },
          { title: 'Math', percentOnProcess: 60 },
          // { title: 'Essay', percentOnProcess: 70 },
          { title: 'Future SAT', percentOnProcess: 80 },
        ]}
        title="Standardized Testing"
        process={1}
        icon={<CalculateOutlinedIcon />}
      />
      <Container className="main-container">
        <div className="px-2" id="General">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <Row className="m-0">
              <div className="text-field-mid fs-3 mb-4">SAT Tests</div>
              <SelectInput
                logo="span-cm-logo"
                form={formik}
                option={[
                  { value: 0 },
                  { value: 1 },
                  { value: 2 },
                  { value: 3 },
                  { value: 4 },
                  { value: 5 },
                ]}
                label="Number of past SAT scores you wish to report*"
                name="number_of_past_sat_scores"
                onChange={(e) => {
                  formik.setFieldValue('number_of_past_sat_scores', `${e.target.value}`);
                  //formik.handleChange(e);
                  formik.setFieldValue(
                    'csu_info.sat_score',
                    setArrayLength(
                      formik.values.csu_info?.sat_score ?? [],
                      e.target.value? parseInt(`${e.target.value}`) : 0,
                      SATSampleItem
                    )
                  );
                }}
                values={formik.values.number_of_past_sat_scores}
              />
              <TextInput
                name="csu_info.collegeBoardId"
                onChange={formik.handleChange}
                values={formik.values.csu_info?.collegeBoardId}
                type="number"
                label="College Board ID"
                logo="span-csu-logo"
                countText={9}
              />
              <YesNoSwitch
                className='mb-3'
                label="Have you taken the SAT essay?"
                name="taken_sat_essay_option"
                values={formik.values.taken_sat_essay_option}
                onChange={formik.handleChange}
                logo="span-cm-logo"
              />

              {(formik.values.csu_info?.sat_score ?? []).map((item, index) => (
                <div key={index}>
                  <SATItem form={formik} index={index} />
                </div>
              ))}
              <div className="fs-5 p-1 span-cm-logo" id="ReadingWriting">
                Super Score
              </div>

              <div className="mt-3">Highest evidence-based reading and writing score*</div>
              <SelectInput
                form={formik}
                option={optionValues(
                  Array.from(Array(61).keys()).map((item) => item * 10 + 200)
                ).reverse()}
                name="highest_evidence_reading_writing_score"
                values={formik.values.highest_evidence_reading_writing_score}
                onChange={formik.handleChange}
                label="Score *"
              />
              <DatePickerInput
                className="common-coallition-flag end-2-flag"
                label="Evidence-based reading and writing date*"
                name="evidence_reading_writing_date"
                values={formik.values.evidence_reading_writing_date}
                onChange={(values) => formik.setFieldValue('evidence_reading_writing_date', values)}
              />
              <div id="Math" />
              <div>Highest math score</div>
              <SelectInput
                form={formik}
                option={optionValues(
                  Array.from(Array(61).keys()).map((item) => item * 10 + 200)
                ).reverse()}
                name="highest_math_score_based_evidence_reading_writing_score"
                values={formik.values.highest_math_score_based_evidence_reading_writing_score}
                onChange={formik.handleChange}
                label="Score *"
              />
              <DatePickerInput
                label="Evidence-based math date*"
                name="math_reading_writing_date"
                values={formik.values.math_reading_writing_date}
                onChange={(values) => formik.setFieldValue('math_reading_writing_date', values)}
              />
              {formik.values.taken_sat_essay_option === 'true' && (
                <>
                  <SelectInput
                    form={formik}
                    option={optionValues(Array.from(Array(19).keys()).map((item) => item + 6))}
                    name="combined_essay_score"
                    values={formik.values.combined_essay_score}
                    onChange={formik.handleChange}
                    label="Highest combined essay score*"
                  />
                  <DatePickerInput
                    className="common-coallition-flag end-2-flag"
                    label="Combined essay date*"
                    name="combined_essay_date"
                    values={formik.values.combined_essay_date}
                    onChange={(values) => formik.setFieldValue('combined_essay_date', values)}
                  />
                </>
              )}

              <div className="border w-100 my-3" id="Essay" />
              <Row>
                <div className="my-3 fs-5 p-1" id="FutureSAT">
                  Future SATs
                </div>
                <SelectInput
                  className="common-flag"
                  form={formik}
                  option={optionValues(Array.from(Array(4).keys()))}
                  label="Number of future SAT sittings you expect*" //"Future SAT Tests You Plan To Take?"
                  name="future_sat_tests_plan_to_take"
                  values={formik.values.future_sat_tests_plan_to_take}
                  onChange={formik.handleChange}
                />
                <div />
                {[1, '1', '2', '3', 2, 3].includes(formik.values.future_sat_tests_plan_to_take) && (
                  <DatePickerInput
                    className="common-flag"
                    label={`Future testing date 1*`}
                    name="test1"
                    values={formFutureTestDate.values?.test1}
                    onChange={(values) => formFutureTestDate.setFieldValue('test1', values)}
                  />
                )}
                {['2', '3', 2, 3].includes(formik.values.future_sat_tests_plan_to_take) && (
                  <DatePickerInput
                    className="common-flag end-flag"
                    label={`Future testing date 2*`}
                    name="test2"
                    values={formFutureTestDate.values?.test2}
                    onChange={(values) => formFutureTestDate.setFieldValue('test2', values)}
                  />
                )}
                {['3', 3].includes(formik.values.future_sat_tests_plan_to_take) && (
                  <DatePickerInput
                    className="common-flag"
                    label={`Future testing date 3*`}
                    name="test3"
                    values={formFutureTestDate.values?.test3}
                    onChange={(values) => formFutureTestDate.setFieldValue('test3', values)}
                  />
                )}
              </Row>
              {/* </>
              )} */}
            </Row>
            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {testing.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default SATTest;
