import { checkEnableParseJSON, formatDate } from 'components/helper/helper';
import React from 'react';
import ItemContent from './ItemContent';
import { Col } from 'react-bootstrap';
import SimpleTable from 'components/table/SimpleTable';
import Stack from '@mui/material/Stack';

const ACTTestSum = (props: any) => {
  const data = {
    ...props.data,
    csu_info: checkEnableParseJSON(props?.data?.csu_info),
  };
  return (
    <>
      <div className="text-field-light fs-5 my-3">ACT Tests</div>

      <ItemContent mdCol={4} values={data?.act_id_number} label="ACT ID number" />
      <ItemContent
        mdCol={4}
        label="Number of past ACT scores you wish to report*"
        values={data?.number_of_act_score_report}
      />
      <ItemContent
        mdCol={4}
        label="Have you taken the ACT Plus Writing test?*"
        values={data?.is_have_act_score_report}
      />
      <SimpleTable
        data={data?.csu_info?.act_score}
        column={[
          { label: 'Test Date', value: 'test_date', formatDate: true },
          { label: 'Composite', value: 'composite_score' },
          { label: 'English', value: 'english' },
          { label: 'Mathematics', value: 'mathematics' },
          { label: 'Reading', value: 'reading' },
          { label: 'Science', value: 'science' },
          {...data?.is_have_act_score_report ? { label: 'Writing', value: 'writing' } : {}},
        ]}
      />

      <ItemContent
        mdCol={2}
        values={data?.superscore_calculated_by_act}
        secondValues=""
        label="Superscore"
      />
      <Col md={10}>
        <Stack direction="row" justifyContent="space-between" alignItems="strech">
          <ItemContent
            label="Highest Composite Score"
            values={data.highest_composite_score}
            mdCol={2}
            secondValues={formatDate(data.highest_composite_test_day)}
          />

          <ItemContent
            mdCol={2}
            label="Highest English Score"
            values={data.highest_english_score}
            secondValues={formatDate(data.highest_english_test_day)}
          />

          <ItemContent
            label="Highest Mathematics Score"
            values={data.highest_mathematic_score}
            mdCol={2}
            secondValues={formatDate(data.highest_mathematic_test_day)}
          />
          <ItemContent
            label="Highest Reading Score"
            values={data.highest_reading_score}
            mdCol={2}
            secondValues={formatDate(data.highest_reading_test_day)}
          />

          <ItemContent
            label="Highest Science Score"
            values={data.highest_science_score}
            mdCol={2}
            secondValues={formatDate(data.highest_science_test_day)}
          />

          <ItemContent
            label="Highest Writing Score"
            values={data.highest_writing_score}
            hidden={!data?.is_have_act_score_report}
            mdCol={2}
            secondValues={formatDate(data.highest_writing_test_day)}
          />
        </Stack>
      </Col>
      <div className="text-field-light">Future ACT Tests You Plan To Take?</div>
      <ItemContent
        label="Number of future ACT sittings you expect*"
        values={data.future_act_test_plan_to_take}
      />
      <ItemContent
        label="Future testing date 1"
        values={[formatDate(checkEnableParseJSON(data.future_testing_date_1)?.test1)]}
      />
      <ItemContent
        label="Future testing date 2"
        values={[formatDate(checkEnableParseJSON(data.future_testing_date_1)?.test2)]}
      />
      <ItemContent
        label="Future testing date 3"
        values={[formatDate(checkEnableParseJSON(data.future_testing_date_1)?.test3)]}
      />
    </>
  );
};

export default ACTTestSum;
