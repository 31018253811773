/* eslint-disable eqeqeq */
import { MenuItem } from '@mui/material';
import React from 'react';
import { Col, Form } from 'react-bootstrap';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { CssSelect } from 'components/helper/helperInut';
type OptionType = any;

interface Props {
  clg?: boolean;
  borderless?: boolean;
  label?: string;
  option: OptionType;
  mdCol?: string;
  name?: string;
  values?: any;
  onChange?: (e: any, child?: React.ReactNode) => void;
  isValid?: boolean;
  feedback?: any;
  defautlValues?: any;
  disabled?: boolean;
  placeholder?: string;
  form?: any;
  className?: string;
  multiple?: boolean;
  logo?: string;
  hidden?: boolean;
  borderType?: number;
  readOnly?: boolean; 
}

const SelectInput = (props: Props) => {
  React.useEffect(() => {
    if (
      props.option?.length &&
      (props.values === undefined || props.values === null || props.values === '')
    ) {
      props.form &&
        props.form.setFieldValue(
          props.name,
          typeof props.option[0] === 'string' ? props.option[0] : props.option[0]?.value
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values]);



    // Fix className content justify-content-xxx
    let className = `d-flex flex-column justify-content-end p-1 p-md-1`;
    if (props.className?.includes("justify-content-")){
      className = className.replace('justify-content-end ', '');
    }
    className =  `${className} ${props.className??''}`.trim();
    let selectClass= props?.readOnly ? 'select-readonly' : undefined;
  return props.hidden ? null : (
    <Form.Group
      className={className}
      name={props.name}
      as={Col}
      md={props.mdCol || '6'}
      controlId={`${props.name}-ID`}
    >     
      <Form.Label className={`select-label label-14px ${props?.logo ?? ''}`.trim()}>{props.label}</Form.Label>
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: '0.5rem',
        }}
      >
        <CssSelect
          className={selectClass}
          id={`${props.name}-ID`}
          disabled={props?.disabled}
          readOnly={props?.readOnly}
          onChange={(e) => {
            props.onChange && props.onChange(e);
          }}
          name={props.name}
          defaultValue={props.defautlValues}
          value={props.values ?? ''}
          placeholder={props.placeholder}
          multiple={props.multiple}
          renderValue={(selected: any) => {
            if (props.multiple === true) {
              if (selected && selected.length > 0 && selected[0].hasOwnProperty('name')) {
                return selected.name.join(', ');
              }
              return selected.join(', ');
            } else {
              switch (typeof selected) {
                case 'string':
                  return selected.charAt(0).toUpperCase() + selected.slice(1);
                case 'object':
                  return selected?.name || selected?.value;
                default:
                  return selected;
              }
            }
          }}
        >
          {(props.option ?? []).map((opt: any, index: number) => {
            if (opt === undefined || opt === null || opt === '') {
              return (
                <MenuItem
                  key={index}
                  value={opt}
                  dense
                  className="w-100"
                  onKeyDown={(e) => e.stopPropagation()}
                >
                  {!props.values ? (
                    <CheckBoxIcon fontSize="small" className="mx-1" color="primary" />
                  ) : (
                    <CheckBoxOutlineBlankIcon fontSize="small" className="mx-1" color="primary" />
                  )}
                </MenuItem>
              );
            } else if (typeof opt === 'string' || typeof opt === 'number') {
              const isChecked = Array.isArray(props.values)
                ? props.values?.indexOf(opt) > -1
                : props.values == opt;

              return (
                <MenuItem
                  key={index}
                  value={opt}
                  dense
                  className="w-100"
                  onKeyDown={(e) => e.stopPropagation()}
                >
                  {isChecked ? (
                    <CheckBoxIcon fontSize="small" className="mx-1" color="primary" />
                  ) : (
                    <CheckBoxOutlineBlankIcon fontSize="small" className="mx-1" color="primary" />
                  )}
                  <span>{opt}</span>
                </MenuItem>
              );
            } else {
              const isChecked =
                props.values !== undefined &&
                (props.values === opt?.id || props.values === opt?.value);

              return (
                <MenuItem
                  key={index}
                  value={opt?.id || opt?.value}
                  dense
                  className="w-100"
                  onKeyDown={(e) => e.stopPropagation()}
                >
                  {isChecked ? (
                    <CheckBoxIcon fontSize="small" className="mx-1" color="primary" />
                  ) : (
                    <CheckBoxOutlineBlankIcon fontSize="small" className="mx-1" color="primary" />
                  )}
                  <span>{opt?.name || opt?.value}</span>
                </MenuItem>
              );
            }
          })}
        </CssSelect>
      </div>

      <div className="sized70 text-field-light w-100 d-flex justify-content-between mh-1rem">
        {props.feedback}
      </div>
    </Form.Group>
  );
};

export default SelectInput;
