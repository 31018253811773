/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import { Chip } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Row, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import TextInput from 'components/input/textInput';
import DatePickerInput from 'components/input/DatePicker';
import GroupCheckbox from 'components/input/GroupCheckbox';
import SelectInput from 'components/input/SelectInput';
import { csuCountry, formatDate, optionValues2 } from 'components/helper/helper';
import moment from 'moment';
import YesNoSwitch from 'components/input/YesNoSwitch';
import collegeServices from 'services/collegeServices';
import GroupRadioButton from 'components/input/GroupRadioButton';
import {
  collegeDetailEarnedOption,
  majorOption,
  minorOption,
  earnDegreeYears,
} from './CollegeConstant';
import CheckboxInput from 'components/input/CheckboxInput';
import { debounce, defer } from 'lodash';
import AutocompleteInput from 'components/input/AutoCompleteInput';
import FormCreateCollegeModal from './FormCreateCollegeModal';
import TextIconInput from 'components/input/TextIconInput';
import LoadingContent from 'components/LoadingContent';
import { canadaProvince, usaState } from 'components/helper/stateAndCounty';
import { countryOptions } from 'components/helper/countryPhoneCode';

export const AliasNames = [
  { alias: 'miracosta', name:'Mira Costa'},
];

export const UnlistedTypes = [ 
  'Unlisted English Speaking Canadian Institution', 
  'Unlisted US Institution', 
  'Unlisted French Speaking Canadian or Other Foreign Institution',
]

//for="academicHistory-collegesAttended-college-unlistedType-444444_radio"

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Props {
  handleSave: (value: any) => void;
  item: any;
  indexText: string;
  index: number;
}


export const initialCollegeValues = {
  college_info: {
    where_attend_college: '',
    state: '',
    country: '',
    college_name: '',
    detail_degree_earned: '',
    when_you_earn_degree_year: '',
    when_you_earn_degree_month: '',
    your_major: '',
    your_minor: '',
    have_double_major: false,
    your_second_major: '',
    type_of_term_systerm: '',
    type_of_term_systerm_uc: '',
    your_tuition_status: '',
    unlisted_type: '',
  },
  where_attend_college: '',
  school: {} as any,                            // college object 
  fullName: '',
  college_name: '',
  college_zip_code: '',
  college_city: '',
  college_ceeb: '',
  college_state: '',
  college_country: '',
  csu_name: '',
  fromDate: '',
  toDate: '',
  college_is_dual_enrollment: false,
  college_is_credit_awarded: false,
  college_is_summer_program: false,
  degree_earned: 'No degree earned',
  degree_info: '',
  expect_to_earn_degree_from_San_Diego_Mesa: '',
  grades: [],
  grades_fall: [],
  grades_winter: [],
  grades_spring: [],
  grades_summer: [],
  college_grading_system_alphabet: false,
  college_grading_system_100: false,
  college_grading_system_7: false,
  college_grading_system_other: false,
  lowest_number_grade: '',
  highest_number_grade: '',
  other_grading_system: '',
  isStillAttendingThisCollege: false,
};

const CollegeConfig = (props: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState('');

  const formItem = useFormik({
    initialValues: initialCollegeValues,
    onSubmit: (values) => {},
  });
  const [expanded, setExpanded] = React.useState(false);
  let searchCtrl = new AbortController();

  const checkCountry = () =>{
    if (formItem.values.school?.country !== 'Canada' && formItem.values.school?.country !== 'United States of America') {      
      formItem.setFieldValue('school.state', null); 
      formItem.setFieldValue('school.province', null);
      if (!formItem.values.school?.state_or_province){
        formItem.setFieldValue('school.state_or_province', '');
      }
    }
  }
 
  React.useEffect(() => {
    if (props.item?.school?.name) {
      setSelectedEducation(props.item?.school);
      checkCountry();
    }
    else {
      const school:any = {
        name: props.item?.fullName,
        ceeb: props.item?.colllege_ceeb,
        zip_code:  props.item?.college_zip_code,
        city: props.item?.colllege_city,
        state: props.item?.colllege_state,
        country: props.item?.colllege_country,
      }
      setSelectedEducation(school);
    }
  }, []);


  const handleFormChange = (e:any) =>{
    checkCountry();
    formItem.handleChange(e);
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  React.useEffect(() => {
    if (props.item)
      formItem.resetForm({
        values: {
          ...formItem.values,
          ...props.item,
          grades: Array.isArray(props.item?.grades) ? props.item?.grades : [props.item?.grades],
          grades_fall: Array.isArray(props.item?.grades_fall)
            ? props.item?.grades_fall
            : [props.item?.grades_fall],
          grades_winter: Array.isArray(props.item?.grades_winter)
            ? props.item?.grades_winter
            : [props.item?.grades_winter],
          grades_spring: Array.isArray(props.item?.grades_spring)
            ? props.item?.grades_spring
            : [props.item?.grades_spring],
          grades_summer: Array.isArray(props.item?.grades_summer)
            ? props.item?.grades_summer
            : [props.item?.grades_summer],
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [gradeOptions, setGradeOptions] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (moment(formItem.values.fromDate).year() && moment(formItem.values.toDate).year()) {
      let dateRange =
        moment(formItem.values.toDate).year() - moment(formItem.values.fromDate).year() + 1;
      if (dateRange > 0) {
        const newGradeOptions = optionValues2(dateRange).map((item) => {
          return { value: item.value + moment(formItem.values.fromDate).year() };
        });
        setGradeOptions([' ', ...newGradeOptions]);
      }
    }
  }, [formItem.values.fromDate, formItem.values.toDate]);

  const [collegeData, setCollegeData] = React.useState<any[]>([]);
  const [selectedEducation, setSelectedEducation] = React.useState<any>();
  const handleGetCollege = async (keyword: string) => {
    try {
      if (keyword && keyword.length > 0) {
        if (keyword?.trim().length===0) return;
        searchCtrl.abort();
        searchCtrl = new AbortController();
        setIsLoading(true);
        const res: any = await collegeServices.getCollegeEx({ keyword: keyword.trim(), limit_item: 20 }, searchCtrl);
        const data = res.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.name,
        }));
        setCollegeData(data);
        setIsLoading(false);
        setOpenMenu(true);        
      }
    }
    catch(ex){
      console.log('Error: ', ex);
    }   
  };
  
  const handleSearchCollege = debounce((keyword: string) => {
    defer(() => {
      handleGetCollege(keyword);
    });
  }, 300);
 

  const handleAliasValue = (value:string) => {
    if (value)  {
      try {
      let keyword = value.toLowerCase().trim();
        // search keyword as alias name
        for(let i=0; i< AliasNames.length; i++) {
          if (keyword === AliasNames[i].alias) {
            return AliasNames[i].name;
          }
        }
      }
      catch(ex){
        console.log('Error:', ex);
      }
    }
    return value;
  };

   React.useEffect(() => {
    if (formItem.dirty) {
      setTimeout(() => {
        props.handleSave({ ...formItem.values });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formItem.values]);

  const handleAddTerm = () => {
    if (formItem.values.grades.length > 3) return;
    formItem.setFieldValue('grades', [...formItem.values.grades, ' ']);
    formItem.setFieldValue('grades_fall', [...formItem.values.grades_fall, ' ']);
    formItem.setFieldValue('grades_winter', [...formItem.values.grades_winter, ' ']);
    formItem.setFieldValue('grades_summer', [...formItem.values.grades_summer, ' ']);
    formItem.setFieldValue('grades_spring', [...formItem.values.grades_spring, ' ']);
  };

  const handleRemoveTerm = (index: number) => {
    formItem.setFieldValue(
      'grades',
      formItem.values.grades.filter((item: any, i: number) => i !== index)
    );
    formItem.setFieldValue(
      'grades_fall',
      formItem.values.grades_fall.filter((item: any, i: number) => i !== index)
    );
    formItem.setFieldValue(
      'grades_winter',
      formItem.values.grades_winter.filter((item: any, i: number) => i !== index)
    );
    formItem.setFieldValue(
      'grades_summer',
      formItem.values.grades_summer.filter((item: any, i: number) => i !== index)
    );
    formItem.setFieldValue(
      'grades_spring',
      formItem.values.grades_spring.filter((item: any, i: number) => i !== index)
    );
  };

  const resetCollegeInfo = () => {
    formItem.setFieldValue('fullName', '');
    formItem.setFieldValue('college_name', '');
    formItem.setFieldValue('college_ceeb', '');
    formItem.setFieldValue('college_zip_code', '');
    formItem.setFieldValue('college_state', '');
    formItem.setFieldValue('college_city', '');
    formItem.setFieldValue('college_country', '');
    formItem.setFieldValue('csu_name', '');
    formItem.setFieldValue('college_info.college_name', '');
    formItem.setFieldValue('college_info.state', '');
    formItem.setFieldValue('country.state', '');
  };

  const [openFormCreate, setOpenFormCreate] = React.useState(false);
  const handleSaveCollege = (values: any) => {
    formItem.setFieldValue('college_code', values?.ceeb);
    formItem.setFieldValue('college_city', values?.city);
    formItem.setFieldValue('college_ceeb', values?.ceeb);

    formItem.setFieldValue('csu_name', values?.csu_name);
    if (!values?.csu_name || values?.csu_name.length==0) {
      formItem.setFieldValue('csu_name', values?.label);
    }
    setOpenFormCreate(false);
  };

  const buildHeader = () => {
    const title = formItem.values.fullName ? formItem.values.fullName.split(' - ')[0] : '';
    const details = [];

    if (formItem.values?.college_ceeb) {
      details.push(`CEEB: ${formItem.values.college_ceeb}`);
    }

    // Add date range if both fromDate and toDate are available
    if (formItem.values?.fromDate && formItem.values?.toDate) {
      details.push(
        `${formatDate(formItem.values.fromDate, 'MM/DD/YYYY')} to ${formatDate(
          formItem.values.toDate,
          'MM/DD/YYYY'
        )}`
      );
    }
    // Add only fromDate if toDate is not available
    else if (formItem.values?.fromDate) {
      details.push(`${formatDate(formItem.values.fromDate, 'MM/DD/YYYY')}`);
    }

    return details.length > 0 ? `${title} - ${details.join(' - ')}` : title;
  };
  return (
    <Card className="w-100 my-3 px-0" key={props.indexText}>
      <CardActions
        disableSpacing
        onClick={handleExpandClick}
        sx={{
          ml: '-8px',
          backgroundColor: expanded ? '#AAEBF7' : 'none',
          borderBottom: expanded ? '2px solid #4D88F0' : 'none',
        }}
      >
        <div className="w-100 d-flex justify-content-between ps-3">
          {buildHeader()}
          <strong className="text-field-light">{props.indexText}</strong>
        </div>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Row className="px-3">
          {/* <CollegeInfo formik={formItem} /> */}
          <GroupRadioButton
              name="college_info.where_attend_college"
              onChange={formItem.handleChange}
              values={formItem.values.college_info?.where_attend_college}
              mdCol={4}
              label="Where did you attend college? *"
              options={['In California', 'Outside California (in the U.S.)', 'Outside the U.S.']}
            />
            {formItem.values.college_info?.where_attend_college === 'Outside California (in the U.S.)' && (
              <>
                <SelectInput
                  name="college_info.state"
                  values={formItem.values.college_info?.state}
                  onChange={(e:any)=>  {
                    formItem.handleChange(e);
                    formItem.setFieldValue('college_state', e.target.value);
                  }}
                  label="State/U.S. territory"
                  option={usaState}
                />
              </>
            )}
            {formItem.values.college_info?.where_attend_college === 'Outside the U.S.' && (
              <SelectInput
                name="college_info.country"
                values={formItem.values.college_info?.country}
                onChange={(e:any)=>  {
                    formItem.handleChange(e);
                    formItem.setFieldValue('college_country', e.target.value);
                }}
                label="Select a country *"
                option={countryOptions}
              />
            )}
            
            <TextIconInput
              label="Search for your College / University"
              logo="span-cm-logo"
              icon={<SearchIcon />}
              mdCol="12"
              onChange={(e: any) => {
                let val = handleAliasValue(e.target.value);
                setSearchInput(val);
                handleSearchCollege(val);
                val = val?.trim();
                formItem.setFieldValue('school.name', val);
                formItem.setFieldValue('college_name', val);
                formItem.setFieldValue('fullName', val);
                if (val.length===0){
                  setCollegeData([]);
                }
              }}
              values={searchInput}
            />
            {isLoading && <LoadingContent />}
            {collegeData !== undefined &&
              !collegeData?.length &&
              !isLoading &&
              !formItem.values.school?.name && <div className="text-center">Not found college</div>}
            {openMenu &&
              (collegeData || []).map((college:any,index: React.Key | null | undefined) =>
                  college?.label ? (
                    <Card className="border rounded my-1 pt-2 pb-1" key={index}>
                      <div className="d-flex align-items-end position-relative">
                        <div className="mx-3 pb-2 ">{college.label}</div>
                        <div className="text-field-light sized70 pb-2">
                          {college.address_line_1}
                        </div>
                        <span className="position-absolute h-100 d-flex align-items-center end-0">
                          <AddIcon
                            fontSize="small"
                            className="bg-app text-primary rounded-circle mx-3 pointer"
                            onClick={() => {
                              setSelectedEducation(college);
                              formItem.setFieldValue('school', college);
                              setOpenMenu(false);
                              setSearchInput('');

                              console.log('college', college);
                              formItem.setFieldValue('fullName',college?.name);
                              formItem.setFieldValue('college_name',college?.name);
                              formItem.setFieldValue('college_ceeb', college?.ceeb);
                              formItem.setFieldValue('college_zip_code', college?.zip_code);
                              formItem.setFieldValue('college_state', college?.state);
                              formItem.setFieldValue('college_city', college?.city);
                              formItem.setFieldValue('college_country', college?.country);
                              formItem.setFieldValue('csu_name', college?.csu_name);

                              formItem.setFieldValue('college_info.college_name', college?.name);
                              formItem.setFieldValue('college_info.state', college?.state);
                              formItem.setFieldValue('college_info.country', college?.country);
                            }}
                          />
                        </span>
                      </div>
                    </Card>
                  ) : null
              )}

            <div className="my-2 text-field-light">Selected College/University</div>
            {selectedEducation?.id ? (
              <div className="d-flex align-items-end position-relative">
                <div className="mx-3 pb-2 fw-bold">{selectedEducation?.name}</div>
                <div className="text-field-light sized70  pb-2">
                  {selectedEducation?.address_line_1}
                </div>
                <span className="position-absolute h-100 d-flex align-items-center end-0">
                  <RemoveIcon
                    fontSize="small"
                    className="bg-light text-dart rounded-circle mx-4 pointer"
                    onClick={() => {
                      setSelectedEducation(undefined);
                      formItem.setFieldValue('school', null);
                      resetCollegeInfo();
                    }}
                  />
                </span>
              </div>
            ) : (collegeData !== undefined && !collegeData?.length) || selectedEducation?.name ? (
              <>
               <Row className="bg-light rounded p-3">
                <TextInput
                  name={'school.name'}
                  values={formItem.values.school?.name}
                  onChange={(e:any)=>  {
                    formItem.handleChange(e);
                    formItem.setFieldValue('fullName', e.target.value);
                    formItem.setFieldValue('college_name', e.target.value);
                    formItem.setFieldValue('college_info.college_name', e.target.value);           
                  }}
                  label="Name of college/university"
                  countText={60}
                />
                 <AutocompleteInput
                  name={'school.country'}
                  form={formItem}
                  values={formItem.values.school?.country}
                  label="Country/Region/Territory*"
                  option={csuCountry}
                  onChange={(e:any)=>{
                    if (formItem.values.school?.country !== 'Canada' && formItem.values.school?.country !== 'United States of America') {      
                      formItem.setFieldValue('school.state', null); 
                      formItem.setFieldValue('school.province', null);
                      if (!formItem.values.school?.state_or_province){
                        formItem.setFieldValue('school.state_or_province', '');
                      }
                    }
                  }}
                />

                <TextInput
                  mdCol={12}
                  name={'school.address_line_1'}
                  values={formItem.values.school?.address_line_1}
                  onChange={handleFormChange}
                  label="Address Line 1"
                  countText={60}
                />
                <TextInput
                  mdCol={12}
                  name={'school.address_line_2'}
                  values={formItem.values.school?.address_line_2}
                  onChange={handleFormChange}
                  label="Address Line 2"
                  countText={60}
                />
                <TextInput
                  mdCol={12}
                  name={'school.address_line_3'}
                  values={formItem.values.school?.address_line_3}
                  onChange={handleFormChange}
                  label="Address Line 3"
                  countText={60}
                />
                <TextInput
                  name={'school.city'}
                  values={formItem.values.school?.city}
                  onChange={(e:any)=>  {
                    formItem.handleChange(e);
                    formItem.setFieldValue('college_city', e.target.value);
                  }}
                  label="City"
                  countText={30}
                />
                <AutocompleteInput
                  option={usaState}
                  form={formItem}
                  hidden={formItem.values.school?.country !== 'United States of America'}
                  label="State"
                  name={'school.state'}
                  values={formItem.values.school?.state}
                  onChange={(value:any)=>{
                    formItem.setFieldValue('college_state', value);
                    formItem.setFieldValue('college_info.state', value);
                  }}
                />
                <AutocompleteInput
                  form={formItem}
                  option={canadaProvince}
                  hidden={formItem.values.school?.country !== 'Canada'}
                  label="Province"
                  name={'school.province'}
                  values={formItem.values.school?.province}
                  onChange={(value:any)=>{
                    formItem.setFieldValue('school.province', value); 
                    formItem.setFieldValue('college_state', value);
                  }}
                />
                <TextInput
                  hidden={
                    formItem.values.school?.country === 'Canada' ||
                    formItem.values.school?.country === 'United States of America'
                  }
                  name={'school.state_or_province'}
                  values={formItem.values.school?.state_or_province}
                  onChange={(e:any)=>{
                    handleFormChange(e);
                    formItem.setFieldValue('school.state', null); 
                    formItem.setFieldValue('school.province', null); 
                    formItem.setFieldValue('school.state_or_province', e.target.value);
                    formItem.setFieldValue('college_state', e.target.value);
                  }}
                  label="State or Province"
                  countText={30}
                />
                <TextInput
                  name={'school.zip_code'}
                  values={formItem.values.school?.zip_code}
                  onChange={handleFormChange}
                  label="Zip Code"
                  countText={10}
                />
                <TextInput
                  name={'school.ceeb'}
                  values={formItem.values.school?.ceeb}
                  onChange={(e:any)=>{
                    handleFormChange(e);
                    formItem.setFieldValue('college_ceeb', e.target.value);
                  }}
                  label="CEEB Code"
                  countText={10}
                />
              </Row>
              </>             
            ) : null}

            <GroupRadioButton
                logo="span-csu-logo"
                name="college_info.unlisted_type"
                onChange={formItem.handleChange}
                values={formItem.values.college_info.unlisted_type}
                mdCol={4}
                label="If you can't find your school in our list, select one of the options below:"
                options={UnlistedTypes}
                onClear={()=>{
                  formItem.setFieldValue('college_info.unlisted_type', ''); 
                }}
              />

              {formItem.values.college_info.unlisted_type === 'Unlisted French Speaking Canadian or Other Foreign Institution' && (
                <>
                <SelectInput
                  logo="span-cm-logo"
                  name="cm_college_info.country"
                  values={formItem.values.college_info?.country}
                  onChange={(e:any)=>  {
                      formItem.handleChange(e);
                      formItem.setFieldValue('college_country', e.target.value);
                  }}
                  label="Please provide your school's country:"
                  option={countryOptions}
                />
                <Col md={6} />
                </>
              )}

            <DatePickerInput
              className="mt-4"
              label={`College "${props.index}" from date`}
              name="fromDate"
              values={formItem.values.fromDate}
              onChange={(value) => formItem.setFieldValue('fromDate', value)}
            />
            <DatePickerInput
              className="mt-4"
              label={`College "${props.index}" to date`}
              name="toDate"
              values={formItem.values.toDate}
              onChange={(value) => formItem.setFieldValue('toDate', value)}
            />
            <CheckboxInput
              logo="span-csu-logo"
              label="Check if you are still attending this college or university"
              name="isStillAttendingThisCollege"
              values={formItem.values.isStillAttendingThisCollege}
              onChange={formItem.handleChange}
            />

            <Row className="common-flag mt-5">
              <GroupCheckbox
                label="Course Details"
                logo="span-cm-logo"
                options={[
                  {
                    value: 'college_is_dual_enrollment',
                    label: 'Dual enrollment with high school,',
                  },
                  {
                    value: 'college_is_credit_awarded',
                    label: 'Credit awarded directly by college ',
                  },
                  { value: 'college_is_summer_program', label: 'Summer program' },
                ]}
                form={formItem}
              />

              <YesNoSwitch
                className="py-3"
                label={`Do you expect to earn a degree from ${formItem.values.fullName}? *`}
                logo="span-cm-logo"
                name="expect_to_earn_degree_from_San_Diego_Mesa"
                values={formItem.values.expect_to_earn_degree_from_San_Diego_Mesa}
                onChange={formItem.handleChange}
              />

              {formItem.values.expect_to_earn_degree_from_San_Diego_Mesa === 'true' && (
                <>
                  <Col md={6}>
                    <GroupRadioButton
                      mdCol={12}
                      label="Degree Info"
                      logo="span-csu-logo"
                      name="degree_info"
                      values={formItem.values.degree_info}
                      onChange={formItem.handleChange}
                      options={['Degree Awarded', 'Degree In Progress']}
                    />
                  </Col>

                  <SelectInput
                    logo="span-cm-logo"
                    label="Degree in progress or will be earned"
                    name="degree_earned"
                    values={formItem.values.degree_earned}
                    onChange={formItem.handleChange}
                    option={[
                      { value: 'AA' },
                      { value: 'AS' },
                      { value: 'BA' },
                      { value: 'BS' },
                      { value: '' },
                    ]}
                  />
                  <SelectInput
                    logo="span-csu-logo"
                    label="Details of degree in progress or will be earned"
                    name="college_info.detail_degree_earned"
                    values={formItem.values.college_info?.detail_degree_earned}
                    onChange={formItem.handleChange}
                    option={collegeDetailEarnedOption}
                  />
                  <div className="fs-6 text-dark span-csu-logo">When did you earn that degree?</div>
                  <SelectInput
                    label="Select Month"
                    name="college_info.when_you_earn_degree_month"
                    values={formItem.values.college_info?.when_you_earn_degree_month}
                    onChange={formItem.handleChange}
                    option={[
                      'January',
                      'February',
                      'March',
                      'April',
                      'May',
                      'June',
                      'July',
                      'August',
                      'September',
                      'October',
                      'November',
                      'December',
                    ]}
                  />
                  <SelectInput
                    label="Select Year"
                    name="college_info.when_you_earn_degree_year"
                    values={formItem.values.college_info?.when_you_earn_degree_year}
                    onChange={formItem.handleChange}
                    option={earnDegreeYears}
                  />
                  <SelectInput
                    logo="span-csu-logo"
                    label="What was your major?"
                    name="college_info.your_major"
                    values={formItem.values.college_info?.your_major}
                    onChange={formItem.handleChange}
                    option={majorOption}
                  />
                  <SelectInput
                    logo="span-csu-logo"
                    label="What was your minor?"
                    name="college_info.your_minor"
                    values={formItem.values.college_info?.your_minor}
                    onChange={formItem.handleChange}
                    option={minorOption}
                  />
                  <CheckboxInput
                    className="py-3"
                    logo="span-csu-logo"
                    label="Check if you were a double major"
                    name="college_info.have_double_major"
                    values={formItem.values.college_info?.have_double_major}
                    onChange={(e) => {
                      formItem.setFieldValue('college_info.have_double_major', e.target.checked);
                    }}
                  />
                  {formItem.values.college_info?.have_double_major === true && (
                    <SelectInput
                      logo="span-csu-logo"
                      label="Select Second Major?"
                      name="college_info.your_second_major"
                      values={formItem.values.college_info?.your_second_major}
                      onChange={formItem.handleChange}
                      option={majorOption}
                    />
                  )}
                </>
              )}
            </Row>
            <GroupCheckbox
              className="pt-3"
              label={`What is this college's grading system? *`}
              logo="span-uc-logo"
              form={formItem}
              mdCol={6}
              options={[
                {
                  value: 'college_grading_system_alphabet',
                  label: 'A B C D F',
                },
                { value: 'college_grading_system_other', label: `Other` },
              ]}
            />
            {formItem.values.college_grading_system_other && (
              <>
                <TextInput
                  className="py-3"
                  logo="span-uc-logo"
                  countText={16}
                  label="Specify the grading system *"
                  values={formItem.values.other_grading_system}
                  onChange={formItem.handleChange}
                  name="other_grading_system"
                />
              </>
            )}
            <GroupRadioButton
              className="py-3"
              label="What type of term system does this college or university use?"
              logo="span-csu-logo"
              options={['Quarter', 'Semester', 'Trimester']}
              values={formItem.values.college_info?.type_of_term_systerm}
              onChange={formItem.handleChange}
              name="college_info.type_of_term_systerm"
            />
            <GroupRadioButton
              className="py-3"
              label="What is this college's term system?"
              logo="span-uc-logo"
              options={['Quarter', 'Semester', 'Semester with Winter Session']}
              values={formItem.values.college_info?.type_of_term_systerm_uc}
              onClear={() => formItem.setFieldValue('college_info.type_of_term_systerm_uc', '')}
              onChange={formItem.handleChange}
              name="college_info.type_of_term_systerm_uc"
            />

            <GroupRadioButton
              label="What was your tuition status (resident or non-resident) at this college or university during the last term you attended?"
              options={['In-State Tuition', 'Out-of-State Tuition', 'Not applicable']}
              values={formItem.values.college_info?.your_tuition_status}
              onChange={formItem.handleChange}
              name="college_info.your_tuition_status"
            />
            <div>During which grades and terms did you attend this college?</div>
            {(formItem.values.grades ?? []).map((item, index) => (
              <Row key={index}>
                <SelectInput
                  logo="span-uc-logo"
                  form={formItem}
                  option={[
                    { value: '' },
                    { value: '9th' },
                    { value: '10th' },
                    { value: '11th' },
                    { value: '12th' },
                  ]}
                  label="Grades"
                  name={`grades[${index}]`}
                  values={formItem.values.grades[index]}
                  onChange={formItem.handleChange}
                />
                <Col md={6} className="d-flex align-items-center justify-content-start">
                  <Chip
                    label="Remove term"
                    className="ms-3 pointer"
                    onClick={() => handleRemoveTerm(index)}
                  />
                </Col>
                <SelectInput
                  mdCol="3"
                  form={formItem}
                  option={gradeOptions}
                  label="Fall"
                  name={`grades_fall[${index}]`}
                  values={formItem.values.grades_fall[index]}
                  onChange={formItem.handleChange}
                />
                <SelectInput
                  mdCol="3"
                  form={formItem}
                  option={gradeOptions}
                  label="Winter"
                  name={`grades_winter[${index}]`}
                  values={formItem.values.grades_winter[index]}
                  onChange={formItem.handleChange}
                />
                <SelectInput
                  mdCol="3"
                  form={formItem}
                  option={gradeOptions}
                  label="Spring"
                  name={`grades_spring[${index}]`}
                  values={formItem.values.grades_spring[index]}
                  onChange={formItem.handleChange}
                />
                <SelectInput
                  mdCol="3"
                  form={formItem}
                  option={gradeOptions}
                  label="Summer - After Academic Year"
                  name={`grades_summer[${index}]`}
                  values={formItem.values.grades_summer[index]}
                  onChange={formItem.handleChange}
                />
              </Row>
            ))}
            {formItem.values.grades.length < 4 && (
              <div className="my-3 w-100 d-flex flex-column align-items-center">
                <div className="text-field-light sized70">You can add up to 4 term/year</div>
                <Button variant="primary" className="p-2 px-5" onClick={handleAddTerm}>
                  Add another term/year
                </Button>
              </div>
            )}

            <Col xs={6} md={9} />
            <Col xs={6} md={3} className="p-3">
              <Button
                className="w-100"
                onClick={() => {
                  props.handleSave(formItem.values);
                  setExpanded(false);
                }}
              >
                Save Info
              </Button>
            </Col>
          </Row>
        </CardContent>
      </Collapse>
      <FormCreateCollegeModal
        open={openFormCreate}
        onClose={() => setOpenFormCreate(false)}
        handleSave={handleSaveCollege}
      />
    </Card>
  );
};

export default CollegeConfig;
