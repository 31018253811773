/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { getGradeAndCourse, updateGradeAndCourse } from 'app/course/courseSlice';
import CheckboxInput from 'components/input/CheckboxInput';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CollegeCourseConfig from './CollegeCourseConfig';
import { debounce } from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { checkEnableParseJSON, reorder } from 'components/helper/helper';
import { getCollege } from 'app/highSchoolSlice/highSchoolSlice';

interface Props {}

const CollegeCourse = (props: Props) => {
  const MAX_COLLEGE_TERMS = 20;
  const [loading, setLoading] = React.useState(false);
  const course = useAppSelector((store) => store.course);
  const dispatch = useAppDispatch();
  const history = useHistory();  
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const [courseData, setCourseData] = React.useState<any[]>([]);
  const formik = useFormik({
    initialValues: {
      college_course_array: '',
      is_finish_adding_all_college_grade: false,
    },
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        student_id: studentId,
        college_course_array: JSON.stringify(courseData),
      };

      await dispatch(updateGradeAndCourse(newValues));
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };  

  const handleContinue = () => {
    formik.handleSubmit();
    history.push(routers.generalTest.path);
  };
  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      try {
        setLoading(true);
        dispatch(getCollege(studentId));
        const res = await dispatch(getGradeAndCourse(studentId)).unwrap();
        const dataLoaded = res;
        if (dataLoaded) {
          formik.setValues({
            ...formik.values,
            ...dataLoaded,
            is_finish_adding_all_college_grade: dataLoaded.is_finish_adding_all_college_grade,
          });
          setCourseData(checkEnableParseJSON(dataLoaded.college_course_array, []));
        }
        setLoading(false);
        formik.setStatus('idle');
      }
      catch(ex){
        console.log('Error: ', ex);
      }
      setLoading(false);
    }
    else {
      handleLogout();
    }
  };
  React.useEffect(() => {
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddCollege = () => {
    if (courseData.length > MAX_COLLEGE_TERMS - 1) return;
    setCourseData([...courseData, {}]);
  };

  // Auto save function ==========
  const notInitialRender = React.useRef(false);
  React.useEffect(() => {
    debounceSaving();
  }, [formik.values, courseData, formik.values.is_finish_adding_all_college_grade]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (notInitialRender.current) {
        if (!loading) formik.handleSubmit();
      } else {
        notInitialRender.current = true;
      }
    }, 1500),
    []
  );
  // Auto save function ==========
  const [forceRenderKey, setForceRenderKey] = React.useState(0);

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const dataList = reorder(courseData, result.source.index, result.destination.index);
    setCourseData([...dataList]);
    setForceRenderKey(forceRenderKey + 1);
  };
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[{ percentOnProcess: 30, title: '' }]}
        title="Courses & Grades"
        process={1}
        icon={<StackedLineChartIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <Row className="m-0">
              <div className="text-field-mid fs-3 mb-4">College Courses</div>

              <DragDropContext onDragEnd={onDragEnd} key={forceRenderKey}>
                <Droppable droppableId="list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {courseData.map((item, index) => (
                        <div key={index + '-' + courseData.length}>
                          <Draggable draggableId={index + '-' + courseData.length} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  key={index + '-' + courseData.length}
                                  className="position-relative"
                                >
                                  <CollegeCourseConfig
                                    handleSave={(values) => {
                                      const newCourseData = [...courseData];
                                      newCourseData.splice(index, 1, values);
                                      setCourseData(newCourseData);
                                    }}
                                    item={item}
                                    index={index + 1}
                                    total={courseData.length}
                                  />
                                  <RemoveCircleOutlineIcon
                                    className="position-absolute text-field-light pointer"
                                    style={{ right: '-24px', top: '12px' }}
                                    onClick={() => {
                                      const newCourseData = [...courseData];
                                      newCourseData.splice(index, 1);
                                      setCourseData(newCourseData);
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        </div>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className="my-3 w-100 d-flex flex-column align-items-center">
                <div className="text-field-light sized70">{`You can add up to ${MAX_COLLEGE_TERMS} College Terms`}</div>
                <Button variant="primary" className="p-2 px-5" onClick={handleAddCollege}>
                  Add College/Term
                </Button>
              </div>
              <div>
                <div className="text-field-light">
                  Please indicate that you added all of the courses you have taken or plan to take
                  for every college
                </div>
                <CheckboxInput
                  name="is_finish_adding_all_college_grade"
                  values={formik.values.is_finish_adding_all_college_grade}
                  onChange={formik.handleChange}
                  label={`I'm finished adding all the college grade classes I've taken or plan to take for every college I've attended. (I can still edit my classes if I need to.)`}
                />
              </div>
            </Row>

            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {course.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default CollegeCourse;
