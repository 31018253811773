import React from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';

interface Props {
  disabled?: boolean;
  readOnly?: boolean;
  defaultValue?: any;
  mdCol?: string;
  label?: string;
  name?: string;
  values?: any;
  onChange?: (e: any) => void;
  isValid?: boolean;
  feedback?: any;
  placeholder?: string;
  icon: any;
  iconClass?: string;
  type?: string;
  className?: string; // label class
  logo?: string;
  formClass?: string;
}

const TextIconInput = (props: Props) => {
  return (
    <Form.Group
      name={props.name}
      className={`d-flex flex-column justify-content-end p-1 p-md-1 ${
        props.className ?? ''
      }`.trim()}
      as={Col}
      md={props.mdCol || '6'}
    >
      <Form.Label style={{ fontSize: '1rem', color: '#4E5A6F' }} className={props.logo}>
        {props.label}
      </Form.Label>
      <InputGroup className="input-with-prefix">
        <div className={`${props.iconClass ?? 'text-field-light'} icon-prefix `}>{props.icon}</div>
        <Form.Control
          className={props.formClass}
          placeholder={props.placeholder}
          disabled={props.disabled}
          readOnly={props.readOnly}
          style={{ paddingLeft: `36px` }}
          type={props.type ?? 'text'}
          defaultValue={props.defaultValue}
          name={props.name}
          value={props.values}
          onChange={props.onChange}
          isValid={props.isValid}
        />
      </InputGroup>
      <div className="sized70 text-field-light w-100 d-flex justify-content-between mh-1rem">
        {props.feedback}
      </div>
      <style scoped>{`
      .input-with-prefix {
        position: relative;
      }
      input.form-control:read-only {
        color: light-dark(rgb(84, 84, 84), rgb(170, 170, 170))!important;
        opacity: 0.9;
      }
      `}</style>
    </Form.Group>
  );
};

export default TextIconInput;
