import GroupRadioButton from 'components/input/GroupRadioButton';
import SelectInput from 'components/input/SelectInput';
import React from 'react';
import CheckboxInput from 'components/input/CheckboxInput';
import TextInput from 'components/input/textInput';
import SelectSearchInput from 'components/input/SelectSearchInput';
import { Col } from 'react-bootstrap';
import { states_usa } from 'components/helper/state_usa';
import { csuCountry, notUsCountry } from 'components/helper/helper';
import courseServices from 'services/courseServices';
import axios from 'axios';

type Props = {
  formik: any;
  isFirstSchool?: boolean;
};

const loadStates = () => {
  const arr = [
    { name: 'International', value: 'N/A' },
    //{ name: "Other/Unknown", value: "N/A"}
  ];
  const states = arr.concat(
    states_usa.filter((obj) => {
      return obj.value !== 'CA';
    })
  );

  return states.map((o) => {
    return o.name;
  });
};


const getStateName = (stateCode: string) => {
  for (let i = 0; i < states_usa.length; i++) {
    if (states_usa[i].value === stateCode) return states_usa[i].name;
  }
  return '';
};

const getStatecode = (stateName: string) => {
  for (let i = 0; i < states_usa.length; i++) {
    if (states_usa[i].name.toLowerCase() === stateName.toLowerCase()) return states_usa[i].value;
  }
  return '';
};


const HighSchoolInfo = ({ formik, isFirstSchool }: Props) => {
  let searchCtrl = new AbortController();
  let states = loadStates();

  React.useEffect(() => {
    if (formik.values.high_school_object && formik.values.high_school_object.college_board_code) {
      let arr:any[] = [];
      arr.push(formik.values.high_school_object);
      setHighSchoolData(arr);
      handleChangeItem(formik.values.high_school_object);
    }
  },[]);



  const [highSchoolData, setHighSchoolData] = React.useState<any[]>([]);

  const optionWhere = [
    { value: 'IN_CA', label: 'In California' },
    { value: 'OUTSIDE_CA', label: 'Outside California (in the U.S.)' },
    { value: 'OUTSIDE_USA', label: 'Outside the U.S.' },
  ];

  const handleSearchHighSchool = async (keyword: string) => {
    if (keyword.length < 1) return;
    searchCtrl.abort();
    searchCtrl = new AbortController();

    try {
      // if (!formik.values.where_attend_high_school){ 
      //      formik.setFieldValues('where_attend_high_school','IN_CA');
      // }

      let res: any = await courseServices.searchHighSchool(
        keyword,
        getStatecode(formik.values.state),
        formik.values.country,
        formik.values.where_attend_high_school,
        searchCtrl
      );
      console.log('searchHighSchool() res:', res);
      const data = res.map((item: any) => ({
        ...item,
        label:
          `${item.high_school_point ?? item.school_name}` +
          ` (code: ${item?.college_board_code ?? ''}, city: ${
            item.city
          }, state: ${item.state}, country: ${item.country})`,
        code: item.college_board_code,
      }));
     
      setHighSchoolData(data);
      
    } catch (error: any) {
      if (axios.isCancel(error)) {
      } else {
        // skip
      }
    }
  };

  const handleChangeItem = (item: any) => {
    try {
      console.log('item:', item);
      item.high_school_name = item.school_name;   // add high_school_name to compatible old object. 

      formik.setFieldValue('school_name', item?.school_name);
      formik.setFieldValue('high_school_name', item?.school_name);
      formik.setFieldValue('high_school_code', item?.college_board_code);
      formik.setFieldValue('high_school_object', item);
      switch (formik.values.where_attend_high_school) {
        case 'IN_CA':
          formik.setFieldValue('state', getStateName('CA'));
          formik.setFieldValue('city', item?.city);
          formik.setFieldValue('csu_city', item?.city);
          formik.setFieldValue('country', 'United States of America');
          break;
        case 'OUTSIDE_CA':
          formik.setFieldValue('state', getStateName(item?.state));
          formik.setFieldValue('city', item?.city);
          formik.setFieldValue('csu_city', item?.city);
          formik.setFieldValue('country', 'United States of America');
          break;
        case 'OUTSIDE_USA':
          break;
        default:
          if (item) {
            if (item.state === 'CA') {
              formik.setFieldValue('state', getStateName('CA'));
              formik.setFieldValue('city', item?.city);
              formik.setFieldValue('csu_city', item?.city);
              formik.setFieldValue('where_attend_high_school', 'IN_CA');
              formik.setFieldValue('country', 'United States of America');
            }
            else {
              formik.setFieldValue('state', getStateName(item?.state));
              formik.setFieldValue('city', item?.city);
              formik.setFieldValue('csu_city', item?.city);
              formik.setFieldValue('where_attend_high_school', 'OUTSIDE_CA');
              formik.setFieldValue('country', 'United States of America');
            }
          }    
          break;
      }
    } catch (ex) {}
  };

  const onChangeWhereAttend = (e: any) => {
    let data = highSchoolData;
    switch (e.target.value) {
      case 'IN_CA':
        data = highSchoolData.filter((i: any) => i.state === 'CA');
        setHighSchoolData(data);
        if (formik.values.high_school_object?.state!=='CA') {
          formik.setFieldValue('high_school_object', null);
          formik.setFieldValue('high_school_name', '');
          formik.setFieldValue('school_name', '');
          formik.setFieldValue('city', '');
          formik.setFieldValue('csu_city', '');
        }
        formik.setFieldValue('state', getStateName('CA'));
        formik.setFieldValue('country', 'United States of America');
        break;
      case 'OUTSIDE_CA':
        data = highSchoolData.filter((i: any) => i.state !== 'CA');
        setHighSchoolData(data);
        if (formik.values.high_school_object?.state==='CA') {
          formik.setFieldValue('high_school_object', null);
          formik.setFieldValue('high_school_name', '');
          formik.setFieldValue('school_name', '');
          formik.setFieldValue('state', '');
          formik.setFieldValue('city', '');
          formik.setFieldValue('csu_city', '');
        }
        formik.setFieldValue('country', 'United States of America');
        break;
      case 'OUTSIDE_USA':
        setHighSchoolData([]);
        break;
    }
    formik.handleChange(e);
    setHighSchoolData([]);
  };

  return (
    <>
      <GroupRadioButton
        name="where_attend_high_school"
        onChange={onChangeWhereAttend}
        values={formik.values.where_attend_high_school}
        mdCol={4}
        logo="span-uc-logo"
        label="Where do/did you attend high school?"
        options={optionWhere}
      />
      {formik.values.where_attend_high_school === 'OUTSIDE_CA' && (
        <>
          <SelectInput
            name="state"
            values={formik.values.state}
            onChange={formik.handleChange}
            label="State/U.S. territory"
            option={states}
            
          />
          <TextInput
            countText={50}
            logo="span-csu-logo"
            label="City"
            name="csu_city"
            values={formik.values.csu_city}
            onChange={formik.handleChange}
          />
        </>
      )}
      {formik.values.where_attend_high_school === 'OUTSIDE_USA' && (
        <>
          <SelectInput
            name="country"
            values={formik.values.country}
            onChange={formik.handleChange}
            label="Select a country *"
            option={notUsCountry}
          />
          <TextInput
            countText={49}
            logo="span-csu-logo"
            label="Academic Performance (High School GPA, Standing, Percentage, etc.)"
            name="academic_performance"
            values={formik.values.academic_performance}
            onChange={formik.handleChange}
          />
          <TextInput
            countText={49}
            logo="span-csu-logo"
            label="Name of Degree/Diploma (e.g. Diploma, A-Level, Abitur, Certificado)"
            name="name_of_degree"
            values={formik.values.name_of_degree}
            onChange={formik.handleChange}
          />
        </>
      )}
      <div className="span-uc-logo">Specialized curriculum (only select if applicable to you)</div>
      <Col md={6}>
        <CheckboxInput
          label="Home School"
          name="is_home_school"
          values={formik.values.is_home_school}
          onChange={formik.handleChange}
        />
      </Col>
      <Col md={6}>
        <CheckboxInput
          label="Independent Study"
          name="is_independant_study"
          values={formik.values.is_independant_study}
          onChange={formik.handleChange}
        />
      </Col>
      {formik.values.is_home_school === true && (
        <>
          <CheckboxInput
            label="My home schooling curriculum is not associated with a high school."
            name="my_home_school_associated_with_high_school"
            values={formik.values.my_home_school_associated_with_high_school}
            onChange={formik.handleChange}
          />
          {formik.values.my_home_school_associated_with_high_school === true && (
            <>
              <div>Manualy Enter School Info</div>
              <TextInput
                name="school_name"
                values={formik.values.school_name}
                onChange={formik.handleChange}
                label="School Name"
                countText={36}
              />
              <TextInput
                name="city"
                values={formik.values.city}
                onChange={formik.handleChange}
                label="City"
                countText={32}
              />
              <SelectInput
                name="school_type"
                values={formik.values.school_type}
                onChange={formik.handleChange}
                label="School Type"
                option={['School Type*', 'Public', 'Private', 'Junior High']}
              />
            </>
          )}
        </>
      )}

      <SelectSearchInput
        mdCol="12"
        form={formik}
        values={`${formik.values.high_school_object?.label ?? formik.values.high_school_name}`}
        name="high_school_name"
        label={
          isFirstSchool ? 'Current or Most Recent Secondary/High School' : 'High School'
        }
        feedback="Search by CEEB code, City, County/Region/Territory, High School Name"
        option={highSchoolData}
        onChangeItem={handleChangeItem}
        onSearch={(keyword: string) => handleSearchHighSchool(keyword)}
        logo="span-uc-logo"        
        className='mt-4 mb-4'
      />
    </>
  );
};

export default HighSchoolInfo;
