/* eslint-disable jsx-a11y/alt-text */
import { Box, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { Container, Button, Image } from 'react-bootstrap';
import { useAppSelector } from 'app/hooks';

import { routers } from 'router/router';
import { useHistory } from 'react-router-dom';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LibraryBooksRoundedIcon from '@mui/icons-material/LibraryBooksRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { get } from 'lodash';
import counselorServices from 'services/counselorServices';
interface Props {}

const DashBoardPage = (props: Props) => {
  const [totalStudent, setTotalStudent] = React.useState(0);
  const history = useHistory();
  const personalStore = useAppSelector((store) => store.basicInformation.myBasicInformation);
  const userRole: string = (personalStore?.user_role ?? '').toLowerCase()?.trim();

  useEffect(() => {
    const userId = get(personalStore, 'student_id', null);
    if (userId && userRole === 'counselor') {
      counselorServices.getPartner(userId).then((res) => {
        setTotalStudent(res?.length);
      });
    }
  }, [personalStore?.student_id, userRole]);
  let role = localStorage.getItem('userRole');
  return (
    <Container>
      <Image src="./assets/images/Banner.svg" width="100%" style={{ marginTop: '2.5rem' }} />
      {role === 'counselor' ? (
        <div className="my-5">
          <Stack direction="row" spacing={2}>
            <span>
              <Image src="./assets/images/Icon-01.svg" roundedCircle width="100px" />
            </span>
            <span>
              <div className="fs-2">Thank you for being a trusted partner!</div>
              <div className="fs-5 p-1 fw-light">
                You have {totalStudent} students who are excited to receive your feedback!
              </div>
              <div className="fs-5 p-1 fw-light">
                Click continue and you'll be able to see all your students on the left-hand side.
              </div>
              <div className="fs-5 p-1 fw-light">
                Select a student and the section summaries will appear for you to review and make
                comments below.
              </div>
              <div className="fs-5 p-1 fw-light">
                If you have any feedback on how we can make this a better experience for you, please
                let us know!
              </div>
              <Button className="my-4" onClick={() => history.push(routers.viewStudent.path)}>
                Continue
              </Button>
            </span>
          </Stack>
        </div>
      ) : (
        <>
          <div className="my-3 bg-light p-3 rounded">
            <Stack direction="row" alignItems={'center'} spacing={2}>
              <Image src="./assets/images/Icon-01.svg" roundedCircle width="110px" />
              <div style={{ fontSize: '32px', fontWeight: 600 }}>
                Welcome to GradMap {personalStore?.first_name ?? ''}!
              </div>
              {/* <div className="p-1" style={{ maxWidth: '90%' }}>
                  I'm Push and I'll be guiding you through the process of organizing, optimizing and
                  auto-filling your college applications while you're in high school. Let's get
                  started!
                </div> */}
            </Stack>
          </div>

          <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent={'center'}
            gap={2}
            sx={{ padding: '40px 80px', borderRadius: '40px', background: '#F7F8FA' }}
          >
            <Stack
              sx={sx.stackGroup}
              alignItems="center"
              onClick={() => history.push(routers.basicInformation.path)}
            >
              <img src="./assets/icons/profile.svg" width={54} style={{ marginTop: 24 }} />
              <Box sx={sx.text}>Profile</Box>
            </Stack>
            <Stack
              sx={sx.stackGroup}
              alignItems="center"
              onClick={() => history.push(routers.household.path)}
            >
              <img src="./assets/icons/family.svg" width={54} style={{ marginTop: 24 }} />
              <Box sx={sx.text}>Family</Box>
            </Stack>
            <Stack
              sx={sx.stackGroup}
              alignItems="center"
              onClick={() => history.push(routers.highSchool.path)}
            >
              <img src="./assets/icons/education.svg" width={54} style={{ marginTop: 24 }} />
              <Box sx={sx.text}>Education</Box>
            </Stack>
            <Stack
              sx={sx.stackGroup}
              alignItems="center"
              onClick={() => history.push(routers.generalTest.path)}
            >
              <img src="./assets/icons/tests.svg" width={54} style={{ marginTop: 24 }} />
              <Box sx={sx.text}>Tests</Box>
            </Stack>
            <Stack
              sx={sx.stackGroup}
              alignItems="center"
              onClick={() => history.push(routers.generalCourse.path)}
            >
              <img src="./assets/icons/course.svg" width={54} style={{ marginTop: 24 }} />
              <Box sx={sx.text}>Courses & Grades</Box>
            </Stack>
            <Stack
              sx={sx.stackGroup}
              alignItems="center"
              onClick={() => history.push(routers.activities.path)}
            >
              <img src="./assets/icons/activity.svg" width={54} style={{ marginTop: 24 }} />
              <Box sx={sx.text}>Activities & Honors</Box>
            </Stack>
            <Stack
              sx={sx.stackGroup}
              alignItems="center"
              onClick={() => history.push(routers.collegeSearch.path)}
            >
              <img src="./assets/icons/college.svg" width={54} style={{ marginTop: 24 }} />
              <Box sx={sx.text}>Colleges</Box>
            </Stack>
            <Stack
              sx={sx.stackGroup}
              alignItems="center"
              onClick={() => history.push(routers.personalSum.path)}
            >
              <ListAltRoundedIcon sx={{ fontSize: '54px', marginTop: '24px', color: '#4d88f0' }} />
              <Box sx={sx.text}>Summary</Box>
            </Stack>
            <Stack
              sx={sx.stackGroup}
              alignItems="center"
              onClick={() => history.push(routers.settings.path + '?tab=3')}
            >
              <SupportAgentIcon sx={{ fontSize: '54px', marginTop: '24px', color: '#4d88f0' }} />
              <Box sx={sx.text}>Counselor</Box>
            </Stack>
            <Stack
              sx={sx.stackGroup}
              alignItems="center"
              onClick={() => history.push(routers.settings.path + '?tab=1')}
            >
              <LibraryBooksRoundedIcon
                sx={{ fontSize: '54px', marginTop: '24px', color: '#4d88f0' }}
              />
              <Box sx={sx.text}>Major Interests</Box>
            </Stack>
            <Stack
              sx={sx.stackGroup}
              alignItems="center"
              onClick={() => window.open('https://docs.gradmap.com/en', '_blank')}
            >
              <InfoRoundedIcon sx={{ fontSize: '54px', marginTop: '24px', color: '#4d88f0' }} />
              <Box sx={sx.text}>Resources</Box>
            </Stack>
            <Stack
              sx={sx.stackGroup}
              alignItems="center"
              onClick={() =>
                window.open(
                  'https://chrome.google.com/webstore/detail/gradmap/fbmaejeklcgdlohlngjkkggddapoklli?hl=en&authuser=0',
                  '_blank'
                )
              }
            >
              <img src="./assets/icons/download.svg" width={54} style={{ marginTop: 24 }} />
              <Box sx={sx.text}>Auto-fill</Box>
            </Stack>
          </Stack>
        </>
      )}
    </Container>
  );
};

const sx = {
  stackGroup: {
    cursor: 'pointer',
    minWidth: '176px !important',
    height: '140px',
    border: '1px solid #D6DBE6',
    backgroundColor: '#FFF',
    borderRadius: '4px',
  },
  text: { maxWidth: '70px', fontSize: '16px', fontWeight: 600, textAlign: 'center' },
};
export default DashBoardPage;
