/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { appRoutes, routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import YesNoSwitch from 'components/input/YesNoSwitch';
import SelectInput from 'components/input/SelectInput';
import TextInput from 'components/input/textInput';
import CollegeConfig, { initialCollegeValues } from './components/CollegeConfig';
import { getCollege, updateCollege } from 'app/highSchoolSlice/highSchoolSlice';
import {
  BoolToString,
  checkEnableParseJSON,
  formatBoolean,
  setArrayLength,
  userpilotDomain,
} from 'components/helper/helper';
import { debounce } from 'lodash';
import { Userpilot } from 'userpilot';

interface Props {}

const CollegeAndUniversity = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const highSchool = useAppSelector((store) => store.highSchool);
  const dispatch = useAppDispatch();
  const history = useHistory();  
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);

  const information = useAppSelector((store) => store.basicInformation.myBasicInformation);
  const formik = useFormik({
    initialValues: {
      is_taken_college_courses: '',
      is_currently_taking_courses_in_college: '',
      number_of_college_credits_have_earned: '0',
      number_of_credits_earned_after_school: '0',
      number_of_colleges_taken_coursework: '0',
      colleges_taken_coursework_array: [],
    },
    onSubmit: async (values: any) => {
      const newValues = {
        ...values,
        student_id: studentId,
        is_taken_college_courses: formatBoolean(values.is_taken_college_courses),
        is_currently_taking_courses_in_college: formatBoolean(
          values.is_currently_taking_courses_in_college
        ),
        colleges_taken_coursework_array: JSON.stringify(values.colleges_taken_coursework_array),
      };
      Userpilot.identify((studentId ?? 'undefined') + userpilotDomain, {
        name: information?.first_name ?? 'undefined',
        email: information?.email ?? 'undefined',
        created_at: information?.created_at ?? 'undefined',
        college: values?.colleges_taken_coursework_array
          .map((item: any) => item.fullName)
          .join(', '),
        termType: values?.colleges_taken_coursework_array
          .map((item: any) => item.type_of_term_systerm)
          .join(', '),
      });
      dispatch(updateCollege(newValues));
    },
  });
  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = () => {
    formik.handleSubmit();
    history.push(appRoutes.futurePlan.path);
  };
  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      const res = await dispatch(getCollege(studentId));
      if (res.payload) {
        formik.resetForm({
          values: {
            ...formik.values,
            ...res.payload,
            is_taken_college_courses: BoolToString(res.payload.is_taken_college_courses),
            is_currently_taking_courses_in_college: BoolToString(
              res.payload.is_currently_taking_courses_in_college
            ),
            colleges_taken_coursework_array: checkEnableParseJSON(
              res.payload.colleges_taken_coursework_array,
              []
            ),
          },
        });
      }
      setLoading(false);
      formik.setStatus('idle');      
    }
    else {
      handleLogout();
    }
  };
  React.useEffect(() => {
    getDetail();
  }, []);

  // Auto save function ==========
  React.useEffect(() => {
    formik.dirty && debounceSaving();
  }, [formik.values]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (!loading) formik.handleSubmit();
    }, 1500),
    []
  );
  // Auto save function ==========
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[]}
        title="Education"
        process={1}
        icon={<StickyNote2OutlinedIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <Row className="m-0">
              <div className="text-field-mid fs-3 mb-4">Colleges & Universities </div>

              <div className="p-1">College Courses or earned College Credit</div>

              <YesNoSwitch
                label="Have you taken college courses or earned college credit? *"
                name="is_taken_college_courses"
                values={formik.values.is_taken_college_courses}
                onChange={formik.handleChange}
              />
              {formik.values.is_taken_college_courses === 'true' && (
                <>
                  <YesNoSwitch
                    label="Are you currently taking courses in college? *"
                    className="coallition-flag py-3"
                    name="is_currently_taking_courses_in_college"
                    values={formik.values.is_currently_taking_courses_in_college}
                    onChange={formik.handleChange}
                  />
                  <div />
                  <TextInput
                    className="coallition-flag py-3"
                    type="number"
                    label="How many college credits have you earned to date? *"
                    name="number_of_college_credits_have_earned"
                    values={formik.values.number_of_college_credits_have_earned}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'number_of_college_credits_have_earned',
                        Math.round(e.target.value * 100) / 100
                      );
                    }}
                    decimalStep={0.1}
                  />
                  <TextInput
                    className="coallition-flag end-flag py-3"
                    type="number"
                    label="How many credits were earned after high school? *"
                    name="number_of_credits_earned_after_school"
                    values={formik.values.number_of_credits_earned_after_school}
                    onChange={(e) =>
                      formik.setFieldValue(
                        'number_of_credits_earned_after_school',
                        Math.round(e.target.value * 100) / 100
                      )
                    }
                    decimalStep={0.1}
                  />
                  <SelectInput
                    logo="span-cm-logo"
                    form={formik}
                    mdCol="12"
                    option={[
                      { value: 0, name: '0 College' },
                      { value: 1, name: '1 College' },
                      { value: 2, name: '2 College' },
                      { value: 3, name: '3 College' },
                      { value: 4, name: '4 College' },
                      { value: 5, name: '5 College' },
                      { value: 6, name: '6 College' },
                    ]}
                    label="Indicate the number of colleges or universities you've taken a course at or received credit from"
                    name="number_of_colleges_taken_coursework"
                    values={formik.values.number_of_colleges_taken_coursework}
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.setFieldValue(
                        'colleges_taken_coursework_array',
                        setArrayLength(
                          formik.values.colleges_taken_coursework_array,
                          e.target.value,
                          initialCollegeValues
                        )
                      );
                    }}
                  />
                  {(formik.values.colleges_taken_coursework_array || []).map(
                    (item: any, index: number) => (
                      <CollegeConfig
                        key={index + '-' + formik.values.colleges_taken_coursework_array?.length}
                        handleSave={(values) => {
                          const newCollegeData: any = [
                            ...formik.values.colleges_taken_coursework_array,
                          ];
                          newCollegeData.splice(index, 1, values);
                          formik.setFieldValue('colleges_taken_coursework_array', newCollegeData);
                        }}
                        item={item}
                        index={index}
                        indexText={`${index + 1}/${
                          formik.values.colleges_taken_coursework_array.length
                        }`}
                      />
                    )
                  )}
                </>
              )}
            </Row>
            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {highSchool.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default CollegeAndUniversity;
