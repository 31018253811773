/* eslint-disable react-hooks/exhaustive-deps */
import '../../pricing-table.css';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PaymentServices from 'services/payment';
import authorizationServices from 'services/authorizationServices';
import { useHistory } from 'react-router';
import Cookies from 'js-cookie';
import { STRIPE_PRICE_NAME } from 'constants/common';
import { routers } from 'router/router';
import { 
  PriceGoldMonth, 
  PriceGoldYear, 
  PriceSilverPlusMonth, 
  PriceSilverPlusYear 
} from 'screen/payment/components/StripePrices';
import { authenticateSettings } from 'components/helper/appConfig';
import { useAppSelector } from 'app/hooks';

interface Props {}
const UserPayment = (props: Props) => {
  let history = useHistory();
  const personalStore = useAppSelector((store) => store.basicInformation.myBasicInformation);
  const params = new URLSearchParams(history.location.search);  
  let priceName = params.get('price')  || ''; 
  const canceled = params.get('canceled')  || '';
  const [selectPrice, setSelectPrice] = useState(1);
  const [showAllPrice, setShowAllPrice] = useState(true);
  const [showMontly, setShowMonthly] = useState(true);
  const [studentEmail, setStudentEmail] = useState('');
  const [studentId, setStudentId] = useState('');

  useEffect(() => { 
    async function getData(student_id:any) {
        let info:any = await authorizationServices.getStudent(personalStore.student_id);
      const { email } = info;
      setStudentEmail(email);        
    }

    if (personalStore && personalStore.student_id) {
      setStudentId(personalStore.student_id);
      sessionStorage.setItem("request_userId", personalStore.student_id);
      getData(personalStore.student_id);
    }
    if (studentId==='' || studentId===':studentId' || studentId===':id'){
      //history.push(`${routers.settings.path}?active-tab=5`);
    }
  },[personalStore]);

  //let adminId = Cookies.get('userId') || '';  
  let token = Cookies.get(authenticateSettings.tokenName);
  let isLogin:boolean = token ? true : false;
 
  useEffect(() => {
      switch(priceName){
        // case 'silver_month':
        //   setShowAllPrice(false);
        //   setShowMonthly(true);
        //   setSelectPrice(1);
        //   Cookies.set("price", priceName);
        //   break;
        case 'silver_plus_month':
          setShowAllPrice(false);
          setShowMonthly(true);
          setSelectPrice(5);
          Cookies.set("price", priceName);
          break;
        case 'gold_month':
          setShowAllPrice(false);  
          setShowMonthly(true);
          setSelectPrice(2);
          Cookies.set("price", priceName);
          break;
        // case 'silver_year':
        //   setShowAllPrice(false);
        //   setShowMonthly(false);
        //   setSelectPrice(3);
        //   Cookies.set("price", priceName);
        //   break;
        case 'silver_plus_year':
          setShowAllPrice(false);
          setShowMonthly(false);
          setSelectPrice(6);
          Cookies.set("price", priceName);
          break;
        case 'gold_year':
          setShowAllPrice(false);
          setShowMonthly(false);
          setSelectPrice(4);
          Cookies.set("price", priceName);
          break;
        default:
          setShowAllPrice(true);
          Cookies.set("price", '');
      }
  }, []);


  const onSelectGoldMonth = (n:number) => {
    setSelectPrice(2);
  }

  const onSelectGoldYear = (n:number) => {
    setSelectPrice(4);
  }

  const onSelectSilverPlusMonth = (n:number) => {
    setSelectPrice(5);
  }
  const onSelectSilverPlusYear = (n:number) => {
    setSelectPrice(6);
  }
  

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const domainUrl = `${window.location.origin}/#`;
    const trialDays = 0; // No trial
    let price_Name = '';
    switch(selectPrice) 
    {
      // case 1:
      //   price_Name  = STRIPE_PRICE_NAME.SilverMonth;
      //   break;
      case 2:
        price_Name = STRIPE_PRICE_NAME.GoldMonth;
        break;
      // case 3:
      //   price_Name  = STRIPE_PRICE_NAME.SilverYear;
      //   break;
      case 4:
        price_Name = STRIPE_PRICE_NAME.GoldYear;
        break;
      case 5:
        price_Name = STRIPE_PRICE_NAME.SilverPlusMonth;
        break;
      case 6:
        price_Name = STRIPE_PRICE_NAME.SilverPlusYear;
        break; 
      }
    if (price_Name) {
      try {
        console.log('price_Name:', price_Name);
        console.log('studentEmail:', studentEmail);
        if (studentEmail) {
            // check payment plan
            let enableExt:any = await PaymentServices.getEnableExtention(studentId);
            if (enableExt) {
              let subcription_status = enableExt.subcription_status;
              if (subcription_status === 'canceled' || 
                  subcription_status === 'deleted')   // manual defined
              {
                let ret = await PaymentServices.deleteStripeSubcription(studentId);
                if (ret) {
                  console.log('Delete stripe_subcription is completed');
                }
                else {
                  console.log('Delete stripe_subcription failed');
                }                
              }
            }
            
            let customer:any = await PaymentServices.getStripeCustomerMappping(studentId);
            let customerId = customer? customer.customer_id : '';
            const checkoutUrl = await PaymentServices.createUserCheckoutSession(domainUrl, price_Name, trialDays, studentId, studentEmail, customerId);
            if (checkoutUrl) {
              window.location.replace(`${checkoutUrl}`);
            }
            else {
              console.log('Error creatting the Checkout Url.');
            }
        }
        else {
          alert("User Email is empty !");
        }
      }
      catch(er){
        console.log('Error: ', er);
      }
    }
  };  
  
  return (    
    <form id="payment-form" onSubmit={handleSubmit}>
<div className='row'>
  <div className="vh-100 overflow-auto p-0 pb-5 col-md-12 col-12">
      {(!isLogin) && (
        <div className="row w-100 px-md-5 pt-md-3" style={{ minHeight: '58px' }}>
          <div className='col-md-12 d-flex mx-3 flex-wrap justify-content-between align-items-center'>
            <div className='d-flex'>
              <button className="btn btn-back" style={{color:"#fff", backgroundColor: "#4d88f0"}}  onClick={()=>{window.history.back();}}>&laquo; Back</button>
            </div>

            <span className="mt-2"><span> Already a member ?</span>
            <button type="button" className="p-0 mx-1 pb-1 btn btn-link">Login</button></span>
          </div>
        </div>
      )}
      <div className='row'>
          <div className="col-md-12 px-5 mt-5 d-flex justify-content-center">
            <h1>Payment</h1>
          </div>
      </div>      
      <div className='row'>
          <div className="col-md-12 px-5 d-flex justify-content-center">
            <div className="radio-block">
              <input type="radio" name="monthly" id="monthly-on" checked={showMontly} onChange={()=>{
                  setShowMonthly(true);
                  switch(selectPrice){
                    // case 3:
                    //   setSelectPrice(1);
                    //   break;
                    case 4:
                      setSelectPrice(2);
                      break;
                    case 6:
                      setSelectPrice(5);
                      break;
                  }
              }} />
              <label className="on-label"  htmlFor="monthly-on">Monthly</label>
              <input type="radio" name="monthly" id="monthly-off" checked={!showMontly} onChange={()=>{
                  setShowMonthly(false);
                  switch(selectPrice){
                    // case 1:
                    //   setSelectPrice(3);
                    //   break;
                    case 2:
                      setSelectPrice(4);
                      break;
                    case 5:
                      setSelectPrice(6);
                      break;
                  }
                }} />
              <label className="off-label" htmlFor="monthly-off">Yearly</label>
              <span className="selected" aria-hidden="true"></span>
            </div>
          </div>
          <div className='col-12 '>
            <div className='price-box mt-4'>
                <div className="PricingTable is-whiteButtonText is-lightColorBackground flex-container direction-column justify-content-center align-items-center">
                    <div className="PricingTable-grid direction-row">
                      {/* {showMontly && (showAllPrice || selectPrice===1) && (
                        <PriceSilverMonth onSelect={onSelectSilverMonth} isSelected={(selectPrice===1)} isRecommended={false} />
                      )} */}
                      {showMontly && (showAllPrice || selectPrice===5) && (
                        <PriceSilverPlusMonth onSelect={onSelectSilverPlusMonth} isSelected={(selectPrice===5)} isRecommended={false} />
                      )}
                      {showMontly && (showAllPrice || selectPrice===2) && (
                        <PriceGoldMonth onSelect={onSelectGoldMonth} isSelected={(selectPrice===2)} isRecommended={true} />
                      )} 
                      {/* {!showMontly && (showAllPrice || selectPrice===3) && (
                        <PriceSilverYear onSelect={onSelectSilverYear} isSelected={(selectPrice===3)} isRecommended={false} />
                      )} */}
                      {!showMontly && (showAllPrice || selectPrice===6) && (
                        <PriceSilverPlusYear onSelect={onSelectSilverPlusYear} isSelected={(selectPrice===6)} isRecommended={false} />
                      )}
                      {!showMontly && (showAllPrice || selectPrice===4) && (
                        <PriceGoldYear onSelect={onSelectGoldYear} isSelected={(selectPrice===4)} isRecommended={true} />
                      )} 
                    </div>
                </div>
          </div>
          </div>
      </div>
  </div>
</div>
    </form>   
  );
}


export default UserPayment;