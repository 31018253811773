/* eslint-disable no-empty-pattern */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { authenticateSettings } from 'components/helper/appConfig';
import { removeIntercomeCache } from 'components/helper/helper';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import basicInformationServices from 'services/basicInformationApi';
import crypto from 'crypto';

export interface BasicInformationState {
  myStripeSubscription: any;
  stripeSubscriptionInfo: any;
  myBasicInformation: any;
  myContactDetail: any;
  myDemographics: any;
  myCitizenship: any;
  listInformation: any[];
  CBOList: any[];
  status: 'idle' | 'loading' | 'failed' ;
  checkModule: any;
  parents: any;
}

const initialState: BasicInformationState = {
  listInformation: [],
  stripeSubscriptionInfo: null,
  myStripeSubscription: null,
  myBasicInformation: null,
  myContactDetail: null,
  myDemographics: null,
  myCitizenship: null,
  checkModule: null,
  parents: null,
  CBOList: [],
  status: 'idle',
};
export const getStripeSubscriptionInfo = createAsyncThunk(
  'basicInformation/getStripeSubscriptionInfo',
  async (studentId: any, {rejectWithValue}) => {
    try {
      const response = await basicInformationServices.getEnableExtention(studentId);
      return response; 
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getCitizenship = createAsyncThunk(
  'basicInformation/getCitizenship',
  async (studentId: any, {rejectWithValue}) => {
    try {
      const response = await basicInformationServices.getCitizenship(studentId);
      return response; 
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getStripeSubscription = createAsyncThunk(
  'basicInformation/getStripeSubscription',
  async (studentId: any, {rejectWithValue}) => {
    try {
      const response = await basicInformationServices.getStripeSubscription(studentId);
      return response;     
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateCitizenship = createAsyncThunk(
  'basicInformation/updateCitizenship',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const myCitizenship = state.basicInformation.myCitizenship;
    try {
      if (myCitizenship) {
        const response = await basicInformationServices.updateCitizenship(payload);
        return response;
      } else {
        const response = await basicInformationServices.createCitizenship(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getDemoGraphics = createAsyncThunk(
  'basicInformation/getDemoGraphics',
  async (studentId: any, {rejectWithValue}) => {
    try {
      const response = await basicInformationServices.getDemoGraphics(studentId);
      return response;
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateDemoGraphics = createAsyncThunk(
  'basicInformation/updateDemoGraphics',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const myDemographics = state.basicInformation.myDemographics;
    try {
      if (myDemographics) {
        const response = await basicInformationServices.updateDemographics(payload);
        return response;
      } else {
        const response = await basicInformationServices.createDemographics(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }    
  }
);
export const getCurrentBasicDetail = createAsyncThunk(
  'basicInformation/getCurrentBasicDetail',
  async (studentId: any, {rejectWithValue}) => {
    try {
      const response: any = await basicInformationServices.getBasicDetail(studentId);
      if(response && response?.email) {
        const secret_key =
        process.env.REACT_APP_INTERCOM_SECRET_KEY || '_YFJ1ViqlxkO7JwPh1kGppKwORC4gpJD0ZzVjwte';

        const hash = crypto.createHmac('sha256', secret_key).update(response?.email).digest('hex');
        response.user_hash = hash;
      }

      return response;
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }    
  }
);
export const getContactDetail = createAsyncThunk(
  'basicInformation/getContactDetail',
  async (studentId: any, {rejectWithValue}) => {
    try {
      const response = await basicInformationServices.getContactDetail(studentId);
      return response;
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateContact = createAsyncThunk(
  'basicInformation/updateContact',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const myContact = state.basicInformation.myContactDetail;
    try {
      if (myContact) {
        const response = await basicInformationServices.updateContact(payload);
        return response;
      } else {
        const response = await basicInformationServices.createContact(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const create = createAsyncThunk('basicInformation/create', async (payload: any, {rejectWithValue}) => {
  try {
    const response = await basicInformationServices.create(payload);
    return response;
  }
  catch (err) {
    const error = err as AxiosError; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});
export const update = createAsyncThunk('basicInformation/update', async (payload: any, {rejectWithValue}) => {
  try {
    const response = await basicInformationServices.update(payload);
    return response; 
  }
  catch (err) {
    const error = err as AxiosError; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});
export const getList = createAsyncThunk('basicInformation/getList', async (params: any, {rejectWithValue}) => {
  try {
    const response = await basicInformationServices.getList(params);
    return response;
  }
  catch (err) {
    const error = err as AxiosError; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});
export const getCBOList = createAsyncThunk('basicInformation/getgetCBOList', async (params: any, {rejectWithValue}) => {
  try {
    const response = await basicInformationServices.getCBOList();
    return response;
  }
  catch (err) {
    const error = err as AxiosError; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});

export const logout = createAsyncThunk('basicInformation/logout', async () => {
  //toast.success("You have been successfully logged out!");
  localStorage.removeItem('currentUserEmail');
  localStorage.removeItem('userRole');
  Cookies.remove(authenticateSettings.tokenName);
  Cookies.remove('userId');
  if (window.Intercom) {
    window.Intercom('shutdown');
  }
  removeIntercomeCache();
  return true;
});
export const basicInformationSlice = createSlice({
  name: 'basicInformation',
  initialState,
  reducers: {
    setCheckModule: (state, action: any) => {
      state.checkModule = action.payload;
    },
    setParent: (state, action: any) => {  
      state.parents = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(create.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(create.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(create.fulfilled, (state, action) => {
        toast.success(action.payload.message);
        state.status = 'idle';
      })
      .addCase(update.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(update.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(update.fulfilled, (state, action) => {
        state.status = 'idle';
        toast.success(action.payload.message);
      })
      .addCase(updateContact.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateContact.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateContact.fulfilled, (state, action) => {
        toast.success(action.payload.message);
        state.myContactDetail = action.payload;
        state.status = 'idle';
      })

      .addCase(getList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getList.fulfilled, (state, action: any) => {
        state.status = 'idle';
        state.listInformation = action.payload;
      })

      .addCase(getCurrentBasicDetail.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCurrentBasicDetail.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(getCurrentBasicDetail.fulfilled, (state, action: any) => {
        state.status = 'idle';
        state.myBasicInformation = action.payload;
      })
      .addCase(getStripeSubscription.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getStripeSubscription.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(getStripeSubscription.fulfilled, (state, action: any) => {
        state.status = 'idle';
        state.myStripeSubscription = action.payload;
      })
      .addCase(getStripeSubscriptionInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getStripeSubscriptionInfo.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(getStripeSubscriptionInfo.fulfilled, (state, action: any) => {
        state.status = 'idle';
        state.stripeSubscriptionInfo = action.payload;
      })
      .addCase(getContactDetail.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getContactDetail.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(getContactDetail.fulfilled, (state, action: any) => {
        state.status = 'idle';
        state.myContactDetail = action.payload;
      })

      .addCase(getDemoGraphics.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getDemoGraphics.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(getDemoGraphics.fulfilled, (state, action: any) => {
        state.status = 'idle';
        if (action.payload) {
          state.myDemographics = action.payload;
        } else {
          state.myDemographics = null;
        }
      })

      .addCase(updateDemoGraphics.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateDemoGraphics.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateDemoGraphics.fulfilled, (state, action: any) => {
        toast.success(action.payload.message);
        state.myDemographics = action.payload;
        state.status = 'idle';
      })
      //
      .addCase(getCitizenship.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCitizenship.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(getCitizenship.fulfilled, (state, action: any) => {
        state.status = 'idle';
        if (action.payload) {
          state.myCitizenship = action.payload;
        } else {
          state.myCitizenship = null;
        }
      })

      .addCase(updateCitizenship.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCitizenship.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateCitizenship.fulfilled, (state, action: any) => {
        toast.success(action.payload.message);
        state.myCitizenship = action.payload;
        state.status = 'idle';
      })

      .addCase(getCBOList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCBOList.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(getCBOList.fulfilled, (state, action: any) => {
        state.CBOList = action.payload.cbo_list_array;
        state.status = 'idle';
      })
      .addCase(logout.fulfilled, (state: any) => {
        state.myBasicInformation = null;
      });
  },
});

export const { setCheckModule, setParent } = basicInformationSlice.actions;
