/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Row, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import TextInput from 'components/input/textInput';
import SelectInput from 'components/input/SelectInput';
import { siblingEducationOptions } from 'components/helper/helper';
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Props {
  handleSave: (value: any) => void;
  item: any;
  total: number;
  current: number;
}

const SiblingsConfig = (props: Props) => {
  const formItem = useFormik({
    initialValues: {
      fullName: undefined,
      firstname: undefined,
      middleName: undefined,
      lastName: undefined,
      age: undefined,
      relationship: undefined,
      educationLevel: undefined,
    },
    onSubmit: (values) => {},
  });
  const [countForm, setCountForm] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  React.useEffect(() => {
    let count = 7;
    const formValues: any = formItem.values;
    Object.keys(formItem.values).forEach((key) => {
      if (formValues[key] === undefined || formValues[key] === '') count--;
    });
    setCountForm(count);
    props.handleSave(formItem.values);
  }, [formItem.values]);
  React.useEffect(() => {
    if (props.item) {
      formItem.setValues(props.item);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (!formItem?.values) return;
    const mergeValue = `${formItem?.values?.firstname ?? ''} ${
      formItem?.values?.middleName ?? ''
    } ${formItem?.values?.lastName ?? ''}`;
    formItem.setFieldValue('fullName', mergeValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formItem.values.firstname, formItem.values.middleName, formItem.values.lastName]);
  return (
    <Card className="w-100 my-3 px-0">
      <CardActions
        disableSpacing
        onClick={handleExpandClick}
        sx={{
          backgroundColor: expanded ? '#AAEBF7' : 'none',
          borderBottom: expanded ? '2px solid #4D88F0' : 'none',

          marginLeft: '-8px',
        }}
      >
        <div className="w-100 d-flex justify-content-between">
          <span className="p-1 ms-3">{formItem.values.fullName}</span>
          <strong className="text-field-light">{props.current + 1}/{props.total}</strong>
        </div>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Row>
            <TextInput
              label="First Name"
              name="firstname"
              values={formItem.values.firstname}
              onChange={formItem.handleChange}
              countText={32}
            />
            <TextInput
              label="Middle Name"
              name="middleName"
              values={formItem.values.middleName}
              countText={32}
              onChange={formItem.handleChange}
            />
            <TextInput
              label="Last Name"
              name="lastName"
              values={formItem.values.lastName}
              countText={32}
              onChange={formItem.handleChange}
            />
            <TextInput
              max={99}
              label="Age"
              name="age"
              values={formItem.values.age}
              onChange={formItem.handleChange}
              type="number"
            />
            <TextInput
              mdCol={'12'}
              label="Sibling Full Name"
              name="fullName"
              countText={50}
              values={formItem.values.fullName}
              onChange={formItem.handleChange}
            />
            <SelectInput
              form={formItem}
              option={[
                { value: 'Brother' },
                { value: 'Step Brother' },
                { value: 'Sister' },
                { value: 'Step Sister' },
              ]}
              label="Relationship"
              name="relationship"
              values={formItem.values.relationship}
              onChange={formItem.handleChange}
            />
            <SelectInput
              form={formItem}
              option={siblingEducationOptions}
              label="Education Level"
              name="educationLevel"
              values={formItem.values.educationLevel}
              onChange={formItem.handleChange}
            />
            <Col xs={6} md={9} />
            <Col xs={6} md={3} className="p-3">
              <Button
                className="w-100"
                onClick={() => {
                  props.handleSave(formItem.values);
                  setExpanded(false);
                }}
              >
                Save Info
              </Button>
            </Col>
          </Row>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default SiblingsConfig;
