/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { authenticateSettings } from 'components/helper/appConfig';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { appMenuGroups, icons } from 'router/router';
import { useHistory, useLocation } from 'react-router';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getHousehold } from 'app/family/familySlice';
import Cookies from 'js-cookie';
import { getGeneral } from 'app/testingSlice/testingSlice';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';

// Menu for Student only
export default function NestedList() {
  const dispatch = useAppDispatch();
  let history = useHistory();
  let location = useLocation();
  const [checkModuleState, setCheckModuleState] = useState<any>(null);
  const [roleFamilyState, setRoleFamilyState] = useState<any>(null);
  const [roleTestState, setRoleTestState] = useState<any>(null);
  const roleFamily = useAppSelector((store) => store.family.roleFamily);
  const roleTest = useAppSelector((store) => store.testing.roleTest);
  const checkModule = useAppSelector((store) => store.basicInformation.checkModule);
  const [open, setOpen] = React.useState<string | null>(null);

  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  
  useEffect(() => {
    appMenuGroups.forEach((route: any) => {
      if (
        route.children.find((e: any) => location?.pathname?.includes(e?.path?.replace('/:id', '')))
      ) {
        setOpen(route.name);
      }
    });
  }, [location]);

  const handleLogout = () => {
    dispatch(logout());
    history.replace(routers.login.path);
  };

  const handleClick = (route: any) => {
    if (open === route.name) return setOpen(null);
    setOpen(route.name);
    if (route.isGroup === false) {
      history.push(route.route.path);
    }
  };

  React.useEffect(() => {
    setRoleFamilyState(roleFamily);
  },[roleFamily]);

  React.useEffect(() => {
    setRoleTestState(roleTest);
  },[roleTest]);

  React.useEffect(() => {
    async function getData() {
      studentId = Cookies.get('userId');
      token = Cookies.get(authenticateSettings.tokenName);
      if (token && studentId) {
        try {
          setCheckModuleState(checkModule);
          
          await dispatch(getHousehold(studentId)).unwrap();
          await dispatch(getGeneral(studentId)).unwrap();
          
        } catch (ex) {
          // force logout
          handleLogout();
        }
      } else {
        // force logout
        handleLogout();
      }
    }
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkModule]);


  function filteredKeys_removeItem(filteredKeys:string[], keyVal:string) {
    var index = filteredKeys.findIndex((e:string) => e === keyVal);
    if (index>-1) {
      filteredKeys.splice(index, 1);    
    }
  }

  function filteredKeys_checkRoleTest(filteredKeys:string[], roleTest:any) {
      if (roleTest) {
        if (roleTest?.satTest === false) {
          filteredKeys_removeItem(filteredKeys, '4.1');   // SAT Tests
        }
        if (roleTest?.actTest === false) {
          filteredKeys_removeItem(filteredKeys, '4.2');   // ACT Tests
        }
        if (roleTest?.actSubjectTest === false) {      
          filteredKeys_removeItem(filteredKeys, '4.3');   // AP Subject Tests
        }
        if (roleTest?.ibSubjectTest === false) {       
          filteredKeys_removeItem(filteredKeys, '4.4');   // IB Subject Tests
        }
        if (roleTest?.clepTest === false) {         
          filteredKeys_removeItem(filteredKeys, '4.5');   // CLEP Tests 
        }
        if (roleTest?.toeflTest === false) {           
          filteredKeys_removeItem(filteredKeys, '4.6');   //  TOEFL iBT
        }        
        if (roleTest?.ieltsAndPte === false) {
          filteredKeys_removeItem(filteredKeys, '4.7');   //  PTE Academic && IELTS
        }
        if (roleTest?.douLingoTest === false) {
          filteredKeys_removeItem(filteredKeys, '4.8');   //  DuoLingo
        }
      }
  }

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
        height: '100%',
        overflow: 'auto',
      }}
      component="nav"
      subheader={
        <ListSubheader component="div" className="text-field-light text-center">
          {/* All changes saved <SaveOutlinedIcon fontSize="small" /> */}
        </ListSubheader>
      }
    >
      {appMenuGroups.map((route: any, index: any) => {
        const IconBefore = icons[route.icon] || 'Component';
        return (
          <div key={index}>
            <ListItemButton
              onClick={() => handleClick(route)}
              className={`px-1 my-1 ${open === route.name && 'bg-light fw-bold'}`}
            >
              <IconBefore fontSize="small" className="me-2 text-secondary" />
              <span className="text-field-mid">{route.name}</span>
              {route.isGroup &&
                (open === route.name ? (
                  <ExpandLess fontSize="small" className="text-field-light" />
                ) : (
                  <ExpandMore fontSize="small" className="text-field-light" />
                ))}
            </ListItemButton>
            {route.isGroup && (
              <Collapse in={open === route.name} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {route.children.map((child: any, i: number) => {
                    let module = '';
                    switch (child?.name) {
                      case 'Personal':
                        module = '1.';
                        break;
                      case 'Family':
                        module = '2.';
                        break;
                      case 'Education':
                        module = '3.';
                        break;
                      case 'Tests':
                        module = '4.';
                        break;
                      case 'Courses & Grades':
                        module = '5.';
                        break;
                      case 'Acitivities & Honors':
                        module = '6.';
                        break;
                      case 'Additional Info':
                        module = '7.';
                        break;
                      default:
                        break;
                    }
                   
                    let filteredKeys = checkModuleState ? Object.keys(checkModuleState).filter((key) => key.startsWith(`${module}`)): [];
                    
                    // remove hiding role Test tab
                    filteredKeys_checkRoleTest(filteredKeys, roleTestState);

                    if(!roleFamilyState?.siblings && filteredKeys.length > 0) {
                      const index = filteredKeys.indexOf('2.4');
                      if (index > -1) {
                        filteredKeys.splice(index, 1);
                      }
                    }

                    if(!roleFamilyState?.stepParent1 && !roleFamilyState?.stepParent2 && filteredKeys.length > 0) {
                      const index = filteredKeys.indexOf('2.5');
                      if (index > -1) {
                        filteredKeys.splice(index, 1);
                      }
                    }

                    const allTrue =
                      filteredKeys.length > 0 &&
                      filteredKeys.every((key) => checkModuleState[key][0] === true);

                    const allCounselorTrue =
                      filteredKeys.length > 0 &&
                      filteredKeys.every((key) => checkModuleState[key][1] === true);

                    const disabled =
                      child.permission && !{ ...roleFamilyState, ...roleTestState }[child.permission];

                    return disabled ? null : (
                      <ListItemButton
                        key={i}
                        sx={{ pl: 2, pt: 2 }}
                        onClick={() => history.push(child?.path.replace(':id', studentId))}
                        disabled={disabled}
                      >
                        <span className="text-secondary">{child?.icon}</span>
                        <span
                          className={
                            location.pathname.includes(child?.path.replace(':id', ''))
                              ? 'text-primary fw-bold'
                              : 'text-field-mid'
                          }
                        >
                          {child?.name}
                        </span>

                        {[
                          'Personal',
                          'Family',
                          'Education',
                          'Tests',
                          'Courses & Grades',
                          'Acitivities & Honors',
                          'Additional Info',
                        ].includes(child?.name) && (
                          <span className="ms-auto">
                            {allTrue && (
                              <img
                                alt="csu"
                                src="./assets/icons/tick-blue.svg"
                                style={{ marginTop: '-8px', marginLeft: '4px' }}
                              />
                            )}
                            {allCounselorTrue && (
                              <img
                                alt="csu"
                                src="./assets/icons/tick-green.svg"
                                style={{ marginTop: '-8px', marginLeft: '4px' }}
                              />
                            )}
                          </span>
                        )}
                      </ListItemButton>
                    );
                  })}
                </List>
              </Collapse>
            )}
          </div>
        );
      })}
    </List>
  );
}
