/* eslint-disable react-hooks/exhaustive-deps */
import { getCollegeData } from 'app/highSchoolSlice/highSchoolSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import CardConfig from 'components/CardConfig';
import {
  checkEnableParseJSON,
  setArrayLength,
} from 'components/helper/helper';
import CheckboxInput from 'components/input/CheckboxInput';
import GroupRadioButton from 'components/input/GroupRadioButton';
import SelectInput from 'components/input/SelectInput';
import TextInput from 'components/input/textInput';
import { FormikProps } from 'formik';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import { FormCourse, SubjectDetail } from 'modal/course';
import React from 'react';
import {
  gradingScale2Options,
  classTypeOption,
  subjectAreaOptions,
  getSubjectsByType,
} from './courseConstant';
import SuggestCourse from './SuggestCourse';

type Props = {
  form: FormikProps<FormCourse>;
  item: SubjectDetail;
  index: number;
  total: number;
  is12?: boolean;
  gradingOptions: any[];
};

const SubjectConfig = (props: Props) => {
  const [title, setTitle] = React.useState('');
  const collegeData = useAppSelector((store) => store.highSchool.collegeData);
  const buildName = (name: string) => {
    return `subjectDetails[${props.index}].${name}`;
  };

  const formItemValues = props.form.values.subjectDetails[props.index];
  const highschoolData = useAppSelector((store) => store.highSchool.highschoolListObject)
    .filter(
      (item) =>
        item.high_school_name === props.form.values.schoolName
    )
    .map((item) => {
      return typeof item.high_school_object === 'object'
        ? item.high_school_object
        : checkEnableParseJSON(item.high_school_object, {});
    })[0];

  const dispatch = useAppDispatch();
  const history = useHistory();  
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  React.useEffect(() => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      if (props.form && !collegeData.length) dispatch(getCollegeData(studentId));      
    }
    else {
      handleLogout();
    }
  }, []);

  const [semesterOption, setSemesterOption] = React.useState<any[]>([]);
  const initSchedule = () => {
    switch (props.form.values.schedule) {
      case 'Semester':
        setSemesterOption([
          { value: 'isFirstSemester', label: 'First Semester', fieldIndex: 0 },
          { value: 'isSecondSemester', label: 'Second Semester', fieldIndex: 1 },
        ]);
        props.form.setFieldValue(buildName('grade'), setArrayLength(props.form.values.subjectDetails[props.index]?.grade, 2, ''));
        props.form.setFieldValue(buildName('credits'), setArrayLength(props.form.values.subjectDetails[props.index]?.credits, 2, ''));
        break;
      case 'Trimester':
        setSemesterOption([
          { value: 'isFirstSemester', label: 'First Trimester', fieldIndex: 0 },
          { value: 'isSecondSemester', label: 'Second Trimester', fieldIndex: 1 },
          { value: 'isThirdSemester', label: 'Third Trimester', fieldIndex: 2 },
        ]);
        props.form.setFieldValue(buildName('grade'), setArrayLength(props.form.values.subjectDetails[props.index]?.grade, 3, ''));
        props.form.setFieldValue(buildName('credits'), setArrayLength(props.form.values.subjectDetails[props.index]?.credits, 3, ''));
        break; 
      case 'Quarters':
        setSemesterOption([
          { value: 'isFirstSemester', label: 'First Quarter', fieldIndex: 0 },
          { value: 'isSecondSemester', label: 'Second Quarter', fieldIndex: 1 },
          { value: 'isThirdSemester', label: 'Third Quarter', fieldIndex: 2 },
          { value: 'isFourthSemester', label: 'Fourth Quarter', fieldIndex: 3 },
        ]);
        props.form.setFieldValue(buildName('grade'), setArrayLength(props.form.values.subjectDetails[props.index]?.grade, 4, ''));
        props.form.setFieldValue(buildName('credits'), setArrayLength(props.form.values.subjectDetails[props.index]?.credits, 4, ''));
        break;
      default:
        setSemesterOption([]);
        props.form.setFieldValue(buildName('grade'), []);
        props.form.setFieldValue(buildName('credits'), []);
    }
    // props.form.setFieldValue(
    //   `subjectDetails[${props.index}].grade`,
    //   setArrayLength(props.form.values.subjectDetails[props.index]?.grade, 4, '')
    // );
    // props.form.setFieldValue(
    //   `subjectDetails[${props.index}].credits`,
    //   setArrayLength(props.form.values.subjectDetails[props.index]?.credits, 4, '')
    // );
  };
  React.useEffect(() => {
    initSchedule();
  }, [props.form.values.schedule]);
 
  const [searchKey, setSearchKey] = React.useState('');

  const renderSC = (b: boolean) => {
    return b ? 'Summer Course' : '';
  };

  const buildStringFromArray = (arr: (string | number)[]) => {
    if (arr && arr.length>0) {
    return arr
      .filter((item, index) => {
        if (index === 0 && !props.form.values?.subjectDetails[props.index]?.isFirstSemester)
          return false;
        if (index === 1 && !props.form.values?.subjectDetails[props.index]?.isSecondSemester)
          return false;
        if (index === 2 && !props.form.values?.subjectDetails[props.index]?.isThirdSemester)
          return false;
        if (index === 3 && !props.form.values?.subjectDetails[props.index]?.isFourthSemester)
          return false;
        return true;
      })
      .filter((i) => !!i)
      .join(', ');
    }
    else{
      return '';
    }
  };
  // const buildFinalGrade = (str?: (string | number)) => {
  //   return str ? <>({str})</> : null;
  // };
  const genTitle = () => {
    let title = "";
    try {
      title+= `${formItemValues.courseName ?? ''} - `;
      title += `${formItemValues.subject ?? ''} : `;
      title += `${buildStringFromArray(props.form.values.subjectDetails[props.index]?.grade)} `;
      
      if (formItemValues?.schedule_transcript !=='One grade per term' ) {
        title += `${formItemValues.finalGrade ?? ''}`;
      }

      title += ` - Credits: ${buildStringFromArray(props.form.values.subjectDetails[props.index]?.credits)} `;

      if (formItemValues?.schedule_transcript !=='One grade per term' ) {
        title += `${formItemValues.finalCredits ?? '' }`;
      }      
    }
    catch(ex){
      console.log('Error:', ex);
    }
    return title;
  }

  React.useEffect(() => {
    setTitle(genTitle());
  }, [props.form.values.CMGradingScale, props.form.values.subjectDetails[props.index]]);


  React.useEffect(() => {
    if (props.form.values.subjectDetails[props.index]?.typeOfClass === 'Dual Enrollment') {
      props.form.setFieldValue(`subjectDetails[${props.index}].isTakeClassAtCollege`, true);
    }
  }, [props.form.values.subjectDetails[props.index]?.typeOfClass]);
  React.useEffect(() => {
    if (props.form.values.subjectDetails[props.index]?.schedule_transcript === 'One final Grade') {
      props.form.setFieldValue(`subjectDetails[${props.index}].credits`, ['', '', '', '']);
      props.form.setFieldValue(`subjectDetails[${props.index}].grade`, ['', '', '', '']);
    }
  }, [props.form.values.subjectDetails[props.index]?.schedule_transcript]);

  return (
    <CardConfig
      headerElement={
        <>
          <span className="p-1">
            {title}
          </span>
          <span className="floatRight" style={{ fontStyle: 'italic' }}>
            {props.form.values.subjectDetails[props.index]?.isSummerCourse &&
              `(${renderSC(props.form.values.subjectDetails[props.index]?.isSummerCourse)})`}
          </span>
          <span className="floatRight">
            <strong className="text-field-light">
              {props.index + 1}/{props.total}
            </strong>
          </span>
        </>
      }
    >
      <CheckboxInput
        logo="span-csu-logo"
        label="Summer Course - Taken After This Grade Year"
        name="isSummerCourse"
        values={props.form.values.subjectDetails[props.index]?.isSummerCourse}
        onChange={(e) => {
          props.form.setFieldValue(
            `subjectDetails[${props.index}].isSummerCourse`,
            e.target.checked
          );
        }}
      />

      <TextInput
        className="common-flag"
        mdCol="12"
        label="Course Name*"
        countText={100}
        name={`subjectDetails[${props.index}].courseName`}
        values={props.form.values.subjectDetails[props.index]?.courseName}
        onChange={(e) => {
          setSearchKey(e.target.value);
          props.form.handleChange(e);
        }}
      />
      {/* New code here for course base on highschool ===*/}
      {searchKey?.length > 0 ? (
        <SuggestCourse
          searchKey={searchKey}
          schoolYear={props.form.values.schoolYear}
          currentHighschool={highschoolData}
          onUpdateCourseTitle={(course) => {
            props.form.setFieldValue(
              `subjectDetails[${props.index}].courseName`,
              course.course_title
            );
            props.form.setFieldValue(
              `subjectDetails[${props.index}].AGSubjectArea`,
              course.subject_area
            );
            props.form.setFieldValue(`schoolYear`, course.course_list_year);
            props.form.setFieldValue(`subjectDetails[${props.index}].otherClass`, '');
          }}
        />
      ) : null}

      {/* === New code here for course base on highschool */}
      <SelectInput
        mdCol="12"
        form={props.form}
        option={subjectAreaOptions}
        label="A-G Subject Area"
        values={props.form.values.subjectDetails[props.index]?.AGSubjectArea}
        name={`subjectDetails[${props.index}].AGSubjectArea`}
        onChange={(e) => {
          //props.form.values.subjectDetails[props.index]?.subject = '';
          props.form.setFieldValue(`subjectDetails[${props.index}].subject`, '');
          props.form.handleChange(e);
        }}
        feedback={
          (props.form.values.subjectDetails[props.index]?.AGSubjectArea?.length ? '' : `Course Does Not Satisfy an A-G Requirement. `)
          + 'If Science, please select D1 - Biological Science or D2 - Physical Science.'
        }
      />
      <SelectInput
        mdCol="12"
        className="common-flag"
        form={props.form}
        option={getSubjectsByType(props.form.values.subjectDetails[props.index]?.AGSubjectArea)}
        label="Subject/Discipline"
        values={props.form.values.subjectDetails[props.index]?.subject}
        name={`subjectDetails[${props.index}].subject`}
        onChange={(e) => {
          props.form.handleChange(e);
        }}
        feedback={`Please choose the closest matching name or "Other' if your class is not on the list.`}
      />

      {props.form.values.subjectDetails[props.index]?.subject?.includes('Other Than English') && (
        <SelectInput
        mdCol="12"
        form={props.form}
        option={[
          'American Sign Language',
          'Arabic',
          'Armenian',
          'Chinese',
          'Farsi',
          'Filipino',
          'French',
          'German',
          'Greek',
          'Hebrew',
          'Hindi',
          'Hmong',
          'Italian',
          'Japanese',
          'Khmer',
          'Korean',
          'Latin',
          'Other Language',
          'Portuguese',
          'Punjabi',
          'Russian',
          'Spanish',
          'Tamil',
          'Turkish',
          'Vietnamese',
        ]}
        label="Language Name"
        values={props.form.values.subjectDetails[props.index]?.languageName}
        name={`subjectDetails[${props.index}].languageName`}
        onChange={props.form.handleChange}
      />
      )}
   
      <SelectInput
        name={`subjectDetails[${props.index}].typeOfClass`}
        label="Type of Class *"
        option={classTypeOption}
        values={props.form.values.subjectDetails[props.index]?.typeOfClass}
        onChange={(e) => {
          props.form.handleChange(e);
        }}
      />
      <SelectInput
        label="Is this a weighted course?"
        option={['', 'Yes', 'No']}
        name={`subjectDetails[${props.index}].isWeightedCourse`}
        values={props.form.values.subjectDetails[props.index]?.isWeightedCourse}
        onChange={props.form.handleChange}
      />

      {
        // props.form.values.subjectDetails[props.index]?.subject === 'Art (Visual or Performing)' ||
        // props.form.values.subjectDetails[props.index]?.subject === 'Computer Science ' ||
        // props.form.values.subjectDetails[props.index]?.subject === 'Religion' ||
        props.form.values.subjectDetails[props.index]?.subject === 'Other' && (
          <TextInput
            label="Class Name on Transcript *"
            countText={50}
            name={`subjectDetails[${props.index}].otherClass`}
            values={props.form.values.subjectDetails[props.index]?.otherClass}
            onChange={props.form.handleChange}
          />
        )
      }

      <div className="border w-100 my-3" />
      <CheckboxInput
        label="I took this class at a college and/or I received college credit for this class."
        name={`subjectDetails[${props.index}].isTakeClassAtCollege`}
        values={props.form.values.subjectDetails[props.index]?.isTakeClassAtCollege}
        onChange={props.form.handleChange}
      />
      {props.form.values.subjectDetails[props.index].isTakeClassAtCollege && (
        <>
          <SelectInput
            label="Select College *"
            option={collegeData}
            mdCol="12"
            name={`subjectDetails[${props.index}].collegeTookClass`}
            values={props.form.values.subjectDetails[props.index]?.collegeTookClass}
            onChange={props.form.handleChange}
          />
          <GroupRadioButton
            mdCol={6}
            label="Grading Scale*"
            name={`subjectDetails[${props.index}].CNGradingScale`}
            onChange={props.form.handleChange}
            options={gradingScale2Options}
            values={props.form.values.subjectDetails[props.index]?.CNGradingScale}
          />
          <div className="text-field-light span-uc-csu-logo">
            Please add the details of this course under your College Courses. Colleges may not allow
            for the course to be listed under the high school coursework on their admission
            application.
          </div>
          {props.form.values.subjectDetails[props.index]?.CNGradingScale === 'Numbers' && (
            <>
              <TextInput
                label="What is the lowest possible number grade at this school? *"
                type="number"
                name={`subjectDetails[${props.index}].lowestGrade`}
                values={props.form.values.subjectDetails[props.index]?.lowestGrade}
                onChange={props.form.handleChange}
              />
              <TextInput
                label="What is the highest possible number grade at this school? *"
                type="number"
                name={`subjectDetails[${props.index}].highestGrade`}
                values={props.form.values.subjectDetails[props.index]?.highestGrade}
                onChange={props.form.handleChange}
              />
            </>
          )}
          {props.form.values.subjectDetails[props.index]?.CNGradingScale === 'Other' && (
            <TextInput
              label="Other Grading Scale*"
              countText={60}
              name={`subjectDetails[${props.index}].otherGradeScale`}
              values={props.form.values.subjectDetails[props.index]?.otherGradeScale}
              onChange={props.form.handleChange}
            />
          )}
        </>
      )}

      <div className="border w-100 my-3" />
      {(props.form.values.schedule === 'Semester' ||
        props.form.values.schedule === 'Trimester' ||
        props.form.values.schedule === 'Quarters') && (
        <div>
          <div className="text-dark">
            During which {props.form.values.schedule.toLowerCase() ?? 'term'}(s) did you take this
            class? *
          </div>

          {semesterOption.map((opt, index) => {
            let key = opt.value as keyof SubjectDetail;
            const handleUncheck = () => {
              let credits = props.form.values.subjectDetails[props.index]?.credits;
              credits[index] = '';
              props.form.setFieldValue(`subjectDetails[${props.index}].credits`, credits);
              let grade = props.form.values.subjectDetails[props.index]?.grade;
              grade[index] = '';
              props.form.setFieldValue(`subjectDetails[${props.index}].grade`, grade);
            };
            return (
              <CheckboxInput
                key={index}
                name={`subjectDetails[${props.index}].[${key}]`}
                label={opt.label}
                onChange={(e: any) => {
                  props.form.handleChange(e);
                  e.target.value === false && handleUncheck();
                }}
                values={props.form.values.subjectDetails[props.index]?.[key]}
              />
            );
          })}
          <GroupRadioButton
            className="py-3"
            mdCol={12}
            options={[
              'One final Grade',
              'One grade per term',
              'One grade per term and one final grade',
            ]}
            label="How are the grades for this class reported on your transcript?"
            name={`subjectDetails[${props.index}].schedule_transcript`}
            values={props.form.values.subjectDetails[props.index]?.schedule_transcript}
            onChange={props.form.handleChange}
            onClear={(e:any) => {
              props.form.setFieldValue(`subjectDetails[${props.index}].schedule_transcript`, '');
              initSchedule();
            }}
          />
        </div>
      )}
      {props.form.values.CMGradingScale !== 'Other' && (
        <>
          {(semesterOption || []).map((opt, index) => {
            let key = opt.value as keyof SubjectDetail;
            let formik = props.form.values.subjectDetails[props.index];

            if (index === 0 && !formik?.isFirstSemester) return <div key={index} />;
            if (index === 1 && !formik?.isSecondSemester) return <div key={index} />;
            if (index === 2 && !formik?.isThirdSemester) return <div key={index} />;
            if (index === 3 && !formik?.isFourthSemester) return <div key={index} />;
            return (
              <React.Fragment key={index}>
                <SelectInput
                  hidden={!formik?.[key] || formik?.schedule_transcript === 'One final Grade'}
                  form={props.form}
                  option={props.gradingOptions}
                  label={`${props.form.values.schedule} ${index + 1} Grade`}
                  defautlValues={''}
                  values={formik?.grade[index]}
                  name={`subjectDetails[${props.index}].grade[${index}]`}
                  onChange={props.form.handleChange}
                />
                <TextInput
                  hidden={!formik?.[key] || formik?.schedule_transcript === 'One final Grade'}
                  label={`${props.form.values.schedule} ${index + 1} Credits`}
                  type="number"
                  decimalStep={0.01}
                  name={`subjectDetails[${props.index}].credits[${index}]`}
                  values={formik?.credits[index]}
                  onChange={(e) =>
                    props.form.setFieldValue(
                      `subjectDetails[${props.index}].credits[${index}]`,
                      Math.round(e.target.value * 100) / 100
                    )
                  }
                />
              </React.Fragment>
            );
          })}
        </>
      )}
      <SelectInput
        hidden={
          props.form.values.subjectDetails[props.index]?.schedule_transcript ===
          'One grade per term'
        }
        form={props.form}
        option={props.gradingOptions}
        label="Final Grade"
        defautlValues={''}
        values={props.form.values.subjectDetails[props.index]?.finalGrade}
        name={`subjectDetails[${props.index}].finalGrade`}
        onChange={props.form.handleChange}
      />
      <TextInput
        hidden={
          props.form.values.subjectDetails[props.index]?.schedule_transcript ===
          'One grade per term'
        }
        label="Final Credits"
        decimalStep={0.01}
        type="number"
        name={`subjectDetails[${props.index}].finalCredits`}
        values={props.form.values.subjectDetails[props.index]?.finalCredits}
        onChange={(e) =>
          props.form.setFieldValue(
            `subjectDetails[${props.index}].finalCredits`,
            Math.round(e.target.value * 100) / 100
          )
        }
      />
      <CheckboxInput
        label="N/A"
        name={`subjectDetails[${props.index}].checkedNA`}
        values={props.form.values.subjectDetails[props.index]?.checkedNA}
        onChange={props.form.handleChange}
      />
      <div className="border w-100 my-3" />
      {/* <CheckboxInput
        label={`${props.form.values.schoolName} used block scheduling for this class.`}
        name={`subjectDetails[${props.index}].isUsedBlockScheduling`}
        values={props.form.values.subjectDetails[props.index]?.isUsedBlockScheduling}
        onChange={props.form.handleChange}
      /> */}
    </CardConfig>
  );
};

export default SubjectConfig;
